import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { LogoGraphContent } from '../../../constants/designGudieContent';
import { buttonDisable } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';
import DesignGraph from '../DesignGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ButtonDisabled({ setCurrActive }: IProps) {
  const { disabled } = LogoGraphContent;
  const buttonDisableRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(buttonDisableRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='disableButton'
        ref={(el) => (buttonDisableRef.current = el as HTMLHeadingElement)}
      >
        비활성화
      </Title>
      <ImageContainer>
        <ButtonDisableImg src={buttonDisable} />
      </ImageContainer>
      <DesignGraph content={disabled} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 82px;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 120px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
  }
`;

const ButtonDisableImg = styled.img`
  width: 860px;
  height: 288px;
  object-fit: contain;
`;

export default ButtonDisabled;
