import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { taxFreeProductComent } from '../../constants/taxFreeContent';
import { isMobile } from '../../util/isMobile';

function TaxFreeProduct() {
  const { title, products } = taxFreeProductComent;
  const processRef = useRef<HTMLLIElement[]>([]);

  const observer = new IntersectionObserver(async (targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    const newProcess: HTMLLIElement[][] = [];
    let newArray: HTMLLIElement[] = [];
    await processRef.current.forEach((item, i) => {
      newArray.push(item);
      if ((i + 1) % 3 === 0) {
        newProcess.push(newArray);
        newArray = [];
      }
    });

    newProcess.forEach((item, i) => {
      setTimeout(() => {
        item.forEach((t: HTMLLIElement) => {
          t.style.transform = 'translateY(0)';
          t.style.animationPlayState = 'running';
        });
      }, 450 * i);
    });
    observer.disconnect();
  });

  useEffect(() => {
    if (isMobile()) {
      observer.observe(processRef.current[0]);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Products>
          {products.map((product, i) => (
            <Product
              key={product.id}
              ref={(el) => (processRef.current[i] = el as HTMLLIElement)}
            >
              <ProductImg src={product.productImg} />
              <ProductText>{product.name}</ProductText>
            </Product>
          ))}
        </Products>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.blueColor.scale1};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 48px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const Title = styled.h1`
  white-space: pre-line;
  margin-bottom: 80px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin-bottom: 20px;
  }
`;

const Products = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px 20px;
  @media ${(props) => props.theme.mobile} {
    gap: 16px 14px;
    padding-bottom: 25px;
  }
`;

const Product = styled.li`
  flex: calc(100% / 5 - 20px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
  border-radius: 8px;

  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    flex-basis: 30%;
    background: ${(props) => props.theme.tintColor.white};
    box-shadow: 0px 4px 16px rgba(218, 222, 228, 0.8);
    transform: translateY(40px);
    transition: transform 0.5s ease-in-out;
    animation: fadeIn 0.5s;
    animation-play-state: paused;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

const ProductImg = styled.img`
  width: 44px;
  height: 44px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 37px;
    height: 37px;
  }
`;

const ProductText = styled.p`
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default TaxFreeProduct;
