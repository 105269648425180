import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { serviceBannerContent } from '../../constants/serviceContent';
import { isMobile } from '../../util/isMobile';

function ServiceBanner() {
  const { title, mobileTitle, content1, content2, img } = serviceBannerContent;

  const imageRef = useRef<HTMLImageElement[]>([]);

  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    imageRef.current[0].style.animationPlayState = 'running';
    imageRef.current[0].style.transform = 'translateY(0)';
    observer.disconnect();
  });

  useEffect(() => {
    observer.observe(imageRef.current[0]);
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{isMobile() ? mobileTitle : title}</Title>
        <ContentContainer>
          <Contents>
            <Content>{content1}</Content>
            <Content>{content2}</Content>
          </Contents>
          <Image
            src={img}
            ref={(el) => (imageRef.current[0] = el as HTMLImageElement)}
          />
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.white};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 96px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const Title = styled.h1`
  white-space: pre-line;
  margin-bottom: 80px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-bottom: 15px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  @media ${(props) => props.theme.mobile} {
    gap: 42px;
  }
`;

const Content = styled.p`
  text-align: left;
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    text-align: center;
  }
`;

const Image = styled.img`
  width: 520px;
  height: 390px;
  object-fit: contain;
  transform: translateY(30px);
  transition: transform 0.8s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  animation-play-state: paused;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 220px;
    margin-top: 48px;
  }
`;

export default ServiceBanner;
