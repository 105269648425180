import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { taxFreeBottomBanner } from '../../constants/taxFreeContent';
import { isMobile } from '../../util/isMobile';

function TaxFreeBottomBanner() {
  const { title, mobileTitle, contents } = taxFreeBottomBanner;
  const imageRef = useRef<HTMLImageElement[]>([]);

  const observer = new IntersectionObserver((targets) => {
    if (isMobile()) {
      targets.forEach((item: any) => {
        if (item.isIntersecting) {
          item.target.style.animationPlayState = 'running';
          item.target.style.transform = 'translateY(0px)';
        }
      });
    } else {
      if (!targets[0].isIntersecting) {
        return;
      }
      imageRef.current.forEach((item, i) => {
        setTimeout(() => {
          item.style.animationPlayState = 'running';
          item.style.transform = 'translateY(0)';
        }, 300 * i - 1);
      });
    }
  });

  useEffect(() => {
    observer.observe(imageRef.current[0]);
    if (isMobile()) {
      observer.observe(imageRef.current[1]);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{isMobile() ? mobileTitle : title}</Title>
        <ContentContainer>
          {contents.map((item, i) => (
            <Content
              key={item.id}
              ref={(el) => (imageRef.current[i] = el as HTMLImageElement)}
            >
              <ContentTitle>{item.title}</ContentTitle>
              <ContentContent>
                {isMobile() ? item.mobileContent : item.content}
              </ContentContent>
              <ContentImg src={item.img} />
            </Content>
          ))}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.white};
  padding: 180px 0;
  border-bottom: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  @media ${(props) => props.theme.mobile} {
    padding: 96px 0;
  }
`;

const Container = styled.div`
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const Title = styled.h1`
  white-space: pre-line;
  margin-bottom: 80px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin-bottom: 48px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 60px;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    gap: 54px;
    padding: 0 10px;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: calc(50% - 60px);
  border-radius: 12px;
  text-align: left;
  box-shadow: 0px 8px 24px rgba(218, 222, 228, 0.8);
  transform: translateY(50px);
  transition: transform 0.5s ease-in-out;
  animation: fadeIn 0.5s;
  animation-play-state: paused;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ContentTitle = styled.h3`
  margin: 44px 0 22px 44px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin: 40px 0px 20px 21px;
  }
`;

const ContentContent = styled.p`
  white-space: pre-line;
  margin-left: 44px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    margin-left: 20px;
  }
`;

const ContentImg = styled.img`
  width: 560px;
  height: 380px;
  margin-top: 80px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 227px;
    margin-top: 20px;
  }
`;

export default TaxFreeBottomBanner;
