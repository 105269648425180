import {
  businessProducts,
  discount,
  receipt,
  shopping,
} from '../util/getImages';

const taxFreeBanner = {
  title:
    'TAX FREE는 외국인 관광객의\n 더 많은 구매를 유도하는 최고의 방법입니다',
  mobileTitle:
    'TAX FREE는 외국인 관광객의\n더 많은 구매를 유도하는\n최고의 방법입니다',
  content:
    '외국인 관광객이 사후 면세판매장으로 등록된 곳에서\n 물품을 구매하고 3개월 이내에 해외로 반출할 시,\n 구매한 물품에 대한 부가가치세를 환급받을 수 있습니다.',
  img: receipt,
};

const taxFreeProductComent = {
  title: '다양한 비즈니스 및 상품에\n TAX FREE를 적용할 수 있습니다',
  products: businessProducts,
};

const taxFreeBottomBanner = {
  title: 'TAX FREE 도입 후의 긍정적인 변화',
  mobileTitle: 'TAX FREE 도입 후의\n긍정적인 변화',
  contents: [
    {
      id: 1,
      title: '외국인 관광객 고객의 증가',
      content:
        'TAX FREE 제도는 전 세계 80여 개국에서 이미 시행되고 있어,\n 외국인 관광객들은 TAX FREE에 대해 높은 인지도를 가지고 있습니다.\n 또한 TAX FREE는 공신력을 가진 제도로, 브랜드 이미지의 신뢰도를 높이고\n 외국인 친화적인 서비스를 제공하여 고객 만족도를 높일 수 있습니다.',
      mobileContent:
        'TAX FREE 제도는 전 세계 80여 개국에서 이미\n시행되고 있어, 외국인 관광객들은 TAX FREE에\n대해 높은 인지도를 가지고 있습니다.\n\n또한 TAX FREE는 공신력을 가진 제도로,\n브랜드 이미지의 신뢰도를 높이고 외국인 친화적인\n서비스를 제공하여고객 만족도를 높일 수 있습니다.',
      img: shopping,
    },
    {
      id: 2,
      title: '매출 손실 없이 가격 경쟁력 향상',
      content:
        'TAX FREE는 면세판매로 총 매출 금액의 변동 없이 외국인 관광객에게\n 할인 혜택을 제공할 수 있습니다. 부가세가 제외된 상품 금액으로\n 타사 대비 높은 가격 경쟁력을 확보할 수 있으며,\n 이를 통해 고객의 더 많은 소비를 촉진할 수 있습니다.',
      mobileContent:
        'TAX FREE는 면세판매로 총 매출 금액의 변동 없이\n외국인 관광객에게 할인 혜택을 제공할 수 있습니다.\n부가세가 제외된 상품 금액으로 타사 대비 높은 가격\n경쟁력을 확보할 수 있으며, 이를 통해 고객의 더 많은\n소비를 촉진할 수 있습니다.',
      img: discount,
    },
  ],
};

export { taxFreeBanner, taxFreeProductComent, taxFreeBottomBanner };
