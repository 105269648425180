import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { downArrow, upArrow } from '../../util/getImages';
import MobileSubDropMenu from './MobileSubDropMenu';

interface IProps {
  menuList: IMenu;
  setIsParentDrop: (isDrop: boolean) => void;
  currActive: string;
}

interface IMenu {
  id: number;
  title: string;
  link: string;
  subMenu: ISubMenu[];
}

export interface ISubMenu {
  id: number;
  title: string;
  link: string;
  list: IList[];
}

export interface IList {
  id: number;
  title: string;
  location: string;
  link?: string;
  list?: ILowestList[];
}

export interface ILowestList {
  id: number;
  title: string;
  list?: any;
  location?: string;
}

function MobileDropDown({ menuList, setIsParentDrop, currActive }: IProps) {
  const [isDrop, setIsDrop] = useState(false);
  const [isCurr, setIsCurr] = useState(false);

  const currentLink = useLocation();

  useEffect(() => {
    if (currentLink.pathname.includes(menuList.link)) {
      setIsDrop(true);
      setIsCurr(true);
    } else {
      setIsCurr(false);
    }
  }, [currentLink]);

  const toggleChange = () => {
    if (currentLink.pathname.includes(menuList.link)) {
      setIsParentDrop(false);
    } else {
      setIsDrop((prev) => !prev);
    }
  };

  return (
    <SideMenuContainer>
      <Header>
        <Title isCurr={isCurr} onClick={toggleChange}>
          {menuList.title}
        </Title>
        <Image src={isDrop ? upArrow : downArrow} />
      </Header>
      <MenuList>
        {isDrop &&
          menuList.subMenu.map((subItem) => (
            <MobileSubDropMenu
              key={subItem.id}
              menuItem={subItem}
              currActive={currActive}
              setIsParentDrop={setIsParentDrop}
            />
          ))}
      </MenuList>
    </SideMenuContainer>
  );
}

const SideMenuContainer = styled.div``;

const Header = styled.div<{ isDrop?: boolean }>`
  display: ${(props) => (props.isDrop ? 'none' : 'flex')};
  justify-content: space-between;
  padding: 20px;
`;

const Title = styled.span<{ isCurr: boolean }>`
  font-size: ${(props) => props.theme.mobileFontSize.medium};
  line-height: ${(props) => props.theme.mobileLineHeight.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  color: ${(props) =>
    props.isCurr
      ? props.theme.grayScaleColor.grayScale1
      : props.theme.grayScaleColor.grayScale2};
  flex: 1;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default MobileDropDown;
