import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IPage } from './SideNavigationBar';

interface IProps {
  pageItem: IPage;
}

function SideNavigationItem({ pageItem }: IProps) {
  const [isCurrLink, setIsCurrLink] = useState(false);
  const { pathname: curUrl } = useLocation();

  useEffect(() => {
    if (pageItem.link === curUrl) {
      setIsCurrLink(true);
    } else {
      setIsCurrLink(false);
    }

    pageItem.list &&
      pageItem.list.forEach((item) => {
        if (item.link === curUrl) {
          setIsCurrLink(true);
        }
      });
  }, [curUrl]);

  return (
    <PageCotainer>
      <Link to={pageItem.link}>
        <PageItem isActive={isCurrLink}>{pageItem.title}</PageItem>
      </Link>
      {pageItem.list && (
        <List>
          {pageItem.list.map((item) => (
            <Link to={item.link} key={item.id}>
              <Item isCurr={item.link === curUrl}>{item.title}</Item>
            </Link>
          ))}
        </List>
      )}
    </PageCotainer>
  );
}

const PageCotainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PageItem = styled.li<{ isActive: boolean }>`
  padding: 10px 110px 10px 16px;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  background: ${(props) =>
    props.isActive
      ? props.theme.tintColor.primaryVarient
      : props.theme.tintColor.white};

  color: ${(props) =>
    props.isActive
      ? props.theme.tintColor.primary
      : props.theme.grayScaleColor.grayScale2};
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 40px;
`;

const Item = styled.li<{ isCurr: boolean }>`
  color: ${(props) =>
    props.isCurr
      ? props.theme.blueColor.scale3
      : props.theme.grayScaleColor.grayScale2};
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

export default SideNavigationItem;
