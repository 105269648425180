import styled from 'styled-components';

interface Content {
  id: number;
  name: string;
  content: string;
}

interface IProps {
  content: Content[];
}

function DesignGraph({ content }: IProps) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>구성 요소</Th>
          <Th>상세 정보</Th>
        </Tr>
      </Thead>
      <Tbody>
        {content.map((item) => (
          <Tr key={item.id}>
            <Td>{item.name}</Td>
            <Td>{item.content}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
`;

const Thead = styled.thead`
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  border-left: none;
  border-right: none;
  th,
  td:first-child {
    border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  }
`;

const Th = styled.th`
  padding: 12px 32px;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

const Td = styled.td`
  white-space: pre-line;
  padding: 12px 32px;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

export default DesignGraph;
