import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TokenRequestContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import DevelopGraph from '../DevelopGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Request({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  const {
    graph: { head, content },
  } = TokenRequestContent;
  return (
    <Container>
      <Title
        id='tokenRequest'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        Request
      </Title>
      <Contents>
        <Content>Method : POST</Content>
        <Content>
          MediaType : <Bold>application/x-www-form-urlencoded</Bold>
        </Content>
        <Content>
          Charset : <Bold isShort> UTF-8</Bold>
        </Content>
        <Content style={{ marginTop: '12px' }}>
          <p style={{ marginBottom: '25px' }}>Specification</p>{' '}
          <DevelopGraph head={head} content={content} isOneLine />
        </Content>

        <Content style={{ marginTop: '12px' }}>
          <p style={{ marginBottom: '25px' }}>Example</p>
          <ExampleContainer>
            <p style={{ width: '460px' }}>
              {
                'curl -v POST "https://openapi.ktaxpay.com/v1/token" \\  \n-d "key=dGVzdF9jbGllbnRfaWQ6dGVzdF9jbGllbnRfc2VjcmV0"'
              }
            </p>
          </ExampleContainer>
        </Content>
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 100px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Content = styled.div``;

const Bold = styled.span<{ isShort?: boolean }>`
  display: inline-block;
  width: ${(props) => (props.isShort ? '110px' : '296px')};
  height: 28px;
  text-align: center;
  border-radius: 8px;
  color: ${(props) => props.theme.tintColor.primary};
  background: ${(props) => props.theme.tintColor.primaryVarient};
  @media ${(props) => props.theme.mobile} {
    width: ${(props) => (props.isShort ? '80px' : '260px')};
    height: 24px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const ExampleContainer = styled.div`
  white-space: pre-wrap;
  padding: 20px;
  border-radius: 12px;
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  font-size: ${(props) => props.theme.fontSize.light};
  line-height: ${(props) => props.theme.lineHeight.regular};
  font-family: monospace;
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

export default Request;
