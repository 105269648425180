import {
  developerHome,
  graphHome,
  coorOrders,
  customs,
  point,
  coorOrdersMobileFirst,
  coorOrdersMobileSecond,
} from '../util/getImages';

const RenderBannerTitle: string =
  '남들보다 먼저 온라인몰에\nTAX FREE를 도입해보세요';

const RenderBannerContent: string =
  'TAX FREE는 비용 없이 외국인 관광객에게 온라인몰을 홍보하고\n제품의 가격 경쟁력을 높일 수 있는 최고의 솔루션입니다.';

const RendingIntroduceContent = [
  {
    id: 1,
    title: '별도 마케팅 비용없이 매출 증대 가능',
    mobileTitle: '별도 마케팅 비용없이 매출 증대 가능',
    content:
      'TAX FREE를 적용하면 매출 손실 없이 부가세만큼 할인되고,\n가격 경쟁력을 확보하여 효과적으로 외국인 관광객 고객을 확보할 수 있습니다.',
    mobileContent:
      'TAX FREE를 적용하면 매출 손실 없이\n부가세만큼 할인되고, 가격 경쟁력을 확보하여\n효과적으로 외국인 관광객 고객을 확보할 수 있습니다.',
    img: graphHome,
  },
  {
    id: 2,
    title: '맞춤형 텍스프리 서비스 KTP',
    mobileTitle: '맞춤형 텍스프리 서비스 KTP',
    content:
      '기업 니즈에 따라 포스 연동, 단말기, 환급방식을\n커스터마이징하여 제공해드립니다.',
    mobileContent:
      '기업 니즈에 따라 포스 연동, 단말기, 환급방식을\n커스터마이징하여 제공해드립니다.',
    img: developerHome,
  },
];

const RendingCooper = {
  title: 'KTP는 여러 공공기관, 업체와 협력하고 있습니다',
  mobileTitle: 'KTP는 여러 공공기관,\n업체와 협력하고 있습니다',
  imgs: coorOrders,
  mobileImgs: [coorOrdersMobileFirst, coorOrdersMobileSecond],
};

const RendingFunctionFirst = {
  title: '스마트 TAX FREE 서비스',
  content:
    'App기반 텍스프리 서비스를 제공하여\n다양한 스마트 기기에서 사용이 가능하며\n다양한 기능을 기업 맞춤으로 제공해드립니다.',
  mobileContent:
    'App기반 텍스프리 서비스를 제공하여\n다양한 스마트 기기에서 사용이 가능하며\n다양한 기능을 기업 맞춤으로 제공해드립니다.',
};

const RendingFunction = [
  {
    id: 1,
    title: '데이터 분석',
    content:
      '외국인 관광객 데이터를 분석해\n마케팅에 활용할 수 있도록 데이터를 제공해드립니다.',
    mobileContent:
      '외국인 관광객 데이터를 분석해\n마케팅에 활용할 수 있도록 데이터를 제공해드립니다.',
    img: customs,
  },
  {
    id: 2,
    title: '간편 해외 결제',
    content:
      '알리페이, 위챗페이 등 해외 간편결제서비스를\n텍스프리와 올인원을 제공합니다.',
    img: point,
  },
];

const RendingProcessContent = [
  {
    id: 1,
    title: '도입문의',
    content:
      "홈페이지의 ‘도입문의' 페이지에서 문의 사항을\n 작성하시면, KTP에서 메일 또는 유선상으로\n 회신 드립니다.",
    mobileContent:
      "홈페이지의 ‘도입문의' 페이지에서\n문의 사항을 작성하시면, KTP에서\n메일 또는 유선상으로 회신 드립니다.",
    order: 1,
  },
  {
    id: 2,
    title: '컨설팅 진행',
    content: 'KTP 담당자를 배정하여\n 심도 있는 컨설팅을 진행합니다.',
    mobileContent: 'KTP 담당자를 배정하여\n심도 있는 컨설팅을 진행합니다.',
    order: 2,
  },
  {
    id: 3,
    title: '기업 맞춤 서비스 제공',
    content:
      '기업 니즈에 맞춰 서비스를 커스텀하고 텍스프리에 필요한 단말기를 제공해드립니다.',
    mobileContent:
      '기업 니즈에 맞춰 서비스를 커스텀하고 텍스프리에 필요한 단말기를 제공해드립니다.',
    order: 3,
  },
];

export {
  RenderBannerTitle,
  RenderBannerContent,
  RendingIntroduceContent,
  RendingCooper,
  RendingFunction,
  RendingProcessContent,
  RendingFunctionFirst,
};
