import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import logoAi from '../../../assets/download/logo_ai.ai';
import logoPng from '../../../assets/download/logo_png.zip';
import { LogoGraphContent } from '../../../constants/designGudieContent';
import { logoGuide } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';
import DesignGraph from '../DesignGraph';
interface IProps {
  setCurrActive: (value: string) => void;
}

function LogoGuide({ setCurrActive }: IProps) {
  const { logo } = LogoGraphContent;
  const logoRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(logoRef.current!);
  }, []);

  return (
    <Container id='logo' ref={(el) => (logoRef.current = el as HTMLDivElement)}>
      <LogContainer>
        <Title>로고</Title>
        <DownLoads>
          <DownLoad href={logoAi} download='로고(AI)'>
            <DownText>로고 다운로드 (AI)</DownText>
          </DownLoad>
          <DownLoad href={logoPng} download='로고(PNG)'>
            <DownText>로고 다운로드 (PNG)</DownText>
          </DownLoad>
        </DownLoads>
      </LogContainer>
      <ImgContainer>
        <LogoGuideImg src={logoGuide} />
      </ImgContainer>
      <ImgContainer>
        <DesignGraph content={logo} />
      </ImgContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${(props) => props.theme.mobile} {
    gap: 24px;
  }
`;

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const DownLoads = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    margin-bottom: 7px;
    gap: 12px;
  }
`;

const DownLoad = styled.a`
  padding: 10px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  background: ${(props) => props.theme.tintColor.primary};
  color: ${(props) => props.theme.tintColor.white};
  :hover {
    opacity: 0.8;
  }
  @media ${(props) => props.theme.mobile} {
    width: 183px;
  }
`;

const DownText = styled.span`
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const ImgContainer = styled.span`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const LogoGuideImg = styled.img`
  width: 860px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 510px;
  }
`;

export default LogoGuide;
