import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { DevelopGraphContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import DesignGraph from '../../DesignGuide/DesignGraph';
import DevelopGraph from '../DevelopGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function KeyAccept({ setCurrActive }: IProps) {
  const { keyAccept, head } = DevelopGraphContent;
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='keyAccept'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        키 발급 받기
      </Title>
      <Content>
        {`테스트 버전, 정식버전으로 API를 사용하려면 가맹점 신청을 진행 해야합니다.\n신청 후 계정에 따라 발급된 API 키를 전달해 드립니다.`}
      </Content>
      <GraphContainer>
        <GraphTitle>키 종류</GraphTitle>
        <DevelopGraph content={keyAccept} head={head} />
      </GraphContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 122px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    scroll-margin-top: 40px;
  }
`;

const Content = styled.p`
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    padding-right: 16px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const GraphContainer = styled.div`
  margin-top: 6px;
`;

const GraphTitle = styled.p`
  margin-bottom: 24px;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

export default KeyAccept;
