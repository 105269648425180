import { check, checkBox, checkBoxActive, close } from '../util/getImages';

const SubmitConents = {
  inquiryTitle: '회사 정보와 문의 내용을 남겨주세요',
  developTitle: '준비중입니다.\n 회사 정보와 문의 내용을 남겨주세요.',
  content:
    '도입 문의, 서비스 이용 절차 등 궁금하신 사항을 남겨주세요.\n문의 내용을 확인하여 빠르게 연락드리도록 하겠습니다.',
  mobileContent:
    '도입 문의, 서비스 이용 절차 등 궁금하신 사항을 남겨주세요. 문의 내용을 확인하여 빠르게 연락드리도록 하겠습니다.',
  checkBox,
  checkBoxActive,
};

const InquiryModal = {
  title: '개인정보 수집 및 이용 방침',
  contentTitle:
    '개인정보 보호법 등 관련 법률에 따라 개인정보 수집 시 정보 주체에게 사전 고지하여 이에 대한 동의를 받습니다.',
  content: [
    '개인정보의 수집 및 이용 목적: 서비스 이용에 따른 상담업무 처리를 위한\n 본인 식별, 본인 의사 확인 및 상담 결과 통보',
    '수집하는 개인정보 항목: 회사명, 이름, 휴대 전화번호, 회사 이메일, 문의 내용',
    '개인정보 보유 및 이용 기간: 동의 일로부터 6개월',
  ],
  img: close,
};

const SubmitCompleteConent = {
  content:
    '문의가 정상적으로 접수되었습니다.\n확인 후, 빠른 시일 내에 회신 드리겠습니다.',
  mobileContent: '문의가 정상적으로\n접수되었습니다.',
  mobileSubContent: '확인 후, 빠른 시일 내에 회신 드리겠습니다.',
  btnContent: '웹사이트로 돌아가기',
};

export { SubmitConents, InquiryModal, SubmitCompleteConent };
