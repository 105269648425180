import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import GuideNavigationBar from '../components/layout/GuideNavigationBar';
import MenuModal from '../components/layout/MenuModal';
import SideNavigationBar from '../components/layout/SideNavigationBar';
import { isMobile } from '../util/isMobile';

function DevelopGuidePage() {
  const [isMenuModal, setIsMenuModal] = useState(false);
  return (
    <>
      {!isMenuModal ? (
        <Wrapper>
          <GuideNavigationBar setIsMenuModal={setIsMenuModal} />
          {!isMobile() && <SideNavigationBar />}
          <Outlet />
        </Wrapper>
      ) : (
        <MenuModal setIsMenuModal={setIsMenuModal} />
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  top: 64px;
  background-color: ${(props) => props.theme.tintColor.white};
`;

export default DevelopGuidePage;
