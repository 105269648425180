import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { RefundInquiryBasicContent } from '../../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../../util/observer';
import refundProcessImg from '../../../../assets/guide/develop/refund_process.png';
import Explain from './Explain';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Basic({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLDivElement>();
  const { title, subTitle } = RefundInquiryBasicContent;

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='refundInquiryBasic'
        ref={(el) => (targetRef.current = el as HTMLDivElement)}
      >
        {title}
      </Title>
      <ContentContainer>
        <SubTitle>{subTitle}</SubTitle>
        <ImageContainer>
          <Image
            src={refundProcessImg}
            width='860px'
            height='840px'
            alt='환급절차.'
          />
        </ImageContainer>
        <Explain />
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin-top: 158px;
  @media ${(props) => props.theme.mobile} {
    scroll-margin-top: 158px;
    gap: 27px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const SubTitle = styled.h3`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Image = styled.img`
  object-fit: contain;
`;

export default Basic;
