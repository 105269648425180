import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LowestDrop from './LowestDrop';
import { IList, ISubMenu } from './MobileDropDown';

interface IProps {
  menuItem: ISubMenu;
  subItem: IList;
  currActive: string;
  setIsParentDrop: (isDrop: boolean) => void;
}

function MobileSubDropItem({
  menuItem,
  subItem,
  currActive,
  setIsParentDrop,
}: IProps) {
  const closeDrop = (e: any) => {
    if (e.target.tagName === 'IMG') {
      return;
    }
    setIsParentDrop(false);
  };

  return (
    <SubContainer key={subItem.id}>
      {subItem.list ? (
        <LowestDrop
          subItem={subItem}
          subItemList={subItem.list}
          link={subItem.link!}
          currActive={currActive}
          setIsParentDrop={setIsParentDrop}
        />
      ) : (
        <SubItem
          currActive={
            currActive === subItem.location ||
            currActive.includes(subItem.location)
          }
          onClick={closeDrop}
        >
          <SubItemHeader>
            <Link
              to={subItem.list ? subItem.link! : menuItem.link}
              state={{ location: subItem.location }}
            >
              <SubText currActive={currActive === subItem.location}>
                {subItem.title}
              </SubText>
            </Link>
          </SubItemHeader>
        </SubItem>
      )}
    </SubContainer>
  );
}

const SubContainer = styled.div``;

const SubItem = styled.li<{ currActive?: boolean }>`
  padding: 0px 60px;
  font-size: 16px;
  line-height: ${(props) => props.theme.mobileLineHeight.small};
  background: ${(props) => props.currActive && props.theme.blueColor.scale3};
`;

const SubItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const SubText = styled.span<{ currActive?: boolean }>`
  font-size: 16px;
  line-height: ${(props) => props.theme.mobileLineHeight.small};
  color: ${(props) =>
    props.currActive
      ? props.theme.tintColor.white
      : props.theme.grayScaleColor.grayScale2};
`;

export default MobileSubDropItem;
