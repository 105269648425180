import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { buttonSize } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ButtonSizeChange({ setCurrActive }: IProps) {
  const sizeChangeRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(sizeChangeRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='sizeChange'
        ref={(el) => (sizeChangeRef.current = el as HTMLHeadingElement)}
      >
        크기 변경
      </Title>
      <ButtonSizeContent>
        Container의 가로 길이를 조절하여 사용해야 하는 경우, 좌우로 동일하게
        확장합니다.
        <br /> Label의 자간 및 크기는 변경하지 않습니다.
      </ButtonSizeContent>
      <ImageContainer>
        <ButtonSizeImg src={buttonSize} />
      </ImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 82px;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 120px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const ButtonSizeContent = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    white-space: normal;
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
  }
`;

const ButtonSizeImg = styled.img`
  width: 860px;
  height: 376px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 600px;
    object-fit: cover;
  }
`;

export default ButtonSizeChange;
