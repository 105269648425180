import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { NavigationBarContent } from '../../constants/designGudieContent';
import { logo, menu } from '../../util/getImages';
import { isMobile } from '../../util/isMobile';
import { INavigationProps } from './NavigationBar';

function GuideNavigationBar({ setIsMenuModal }: INavigationProps) {
  const pageInfo = NavigationBarContent;
  const curUrl = useLocation();

  pageInfo.forEach((page) => {
    if (curUrl.pathname.includes(page.link)) {
      page.active = true;
    } else {
      page.active = false;
    }
  });

  return (
    <Container>
      <LogoContainer>
        <Link to={'/'}>
          <LogoIcon src={logo} />
        </Link>
      </LogoContainer>
      {isMobile() ? (
        <MenuImg src={menu} onClick={() => setIsMenuModal(true)} />
      ) : (
        <PageList>
          {pageInfo.map((page) => (
            <PageItem key={page.id} isActive={page.active}>
              <Link to={page.link}>{page.title}</Link>
            </PageItem>
          ))}
        </PageList>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 116px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 16px 65px;
  background: ${(props) => props.theme.tintColor.white};
  border-bottom: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  @media ${(props) => props.theme.mobile} {
    padding: 24px 21px;
    gap: 0;
    justify-content: space-between;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoIcon = styled.img`
  display: flex;
  height: 34px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 86px;
    height: 24px;
  }
`;

const MenuImg = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const PageList = styled.ul`
  display: flex;
  gap: 80px;
`;

const PageItem = styled.li<{ isActive: boolean }>`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  a {
    color: ${(props) =>
      props.isActive
        ? props.theme.tintColor.primary
        : props.theme.grayScaleColor.grayScale2};
  }
`;

export default GuideNavigationBar;
