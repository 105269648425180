import styled from 'styled-components';
import { serviceDetailContent } from '../../constants/serviceContent';
import { isMobile } from '../../util/isMobile';
import DetailMobileProcess from './DetailMobileProcess';
import DetailProcess from './DetailProcess';

function ServiceDetail() {
  const { title, content, mobileContent } = serviceDetailContent;
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Content>{isMobile() ? mobileContent : content}</Content>
        {isMobile() ? <DetailMobileProcess /> : <DetailProcess />}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.white};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 96px 0;
  }
`;

const Container = styled.div`
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  @media ${(props) => props.theme.mobile} {
    padding: 0px;
  }
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin-bottom: 15px;
  }
`;

const Content = styled.p`
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.normal};
  margin-bottom: 80px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    margin-bottom: 32px;
  }
`;

const Button = styled.button`
  width: 148px;
  height: 44px;
  border-radius: 8px;
  margin: 48px 0 80px 0;
  color: ${(props) => props.theme.tintColor.white};
  background: ${(props) => props.theme.tintColor.primary};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  :hover {
    opacity: 0.8;
  }
  @media ${(props) => props.theme.mobile} {
    margin: 44px 0 52px 0;
    :active {
      opacity: 0.8;
    }
  }
`;

export default ServiceDetail;
