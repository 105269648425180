import { useState } from 'react';
import Footer from '../components/Common/Footer';
import Home from '../components/layout/Home';
import MenuModal from '../components/layout/MenuModal';
import NavigationBar from '../components/layout/NavigationBar';
import RendingBottomBanner from '../components/Rending/RendingBottomBanner';
import ServiceBanner from '../components/Service/ServiceBanner';
import ServiceDetail from '../components/Service/ServiceDetail';
import ServiceFeature from '../components/Service/ServiceFeature';
import ServiceProcess from '../components/Service/ServiceProcess';

function ServiceIntroducePage() {
  const [isMenuModal, setIsMenuModal] = useState(false);
  return (
    <>
      {!isMenuModal ? (
        <>
          <NavigationBar setIsMenuModal={setIsMenuModal} />
          <Home />
          <ServiceBanner />
          <ServiceProcess />
          <ServiceDetail />
          <ServiceFeature />
          <RendingBottomBanner />
          <Footer />
        </>
      ) : (
        <MenuModal setIsMenuModal={setIsMenuModal} />
      )}
    </>
  );
}

export default ServiceIntroducePage;
