import React from 'react';
import InquiryMain from '../components/inquiry/InquiryMain';

function InquiryPage() {
  return (
    <>
      <InquiryMain />
    </>
  );
}

export default InquiryPage;
