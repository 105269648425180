import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ErrorGraphContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import ErrorGraph from './ErrorGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Basic({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container
      id='errorBasic'
      ref={(el) => (targetRef.current = el as HTMLDivElement)}
    >
      <Title>에러코드</Title>
      <SubTitle>기본 요청 오류</SubTitle>
      <GraphContainer>
        <ErrorGraph content={ErrorGraphContent.basic} />
      </GraphContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  scroll-margin-top: 158px;
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const SubTitle = styled.h3`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const GraphContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

export default Basic;
