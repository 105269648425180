import logo from '../assets/common/logo.png';
import logoWhite from '../assets/common/logo_white.png';
import developerHome from '../assets/rending/developer_home.png';
import graphHome from '../assets/rending/graph_home.png';
import customs from '../assets/rending/customs.png';
import passport from '../assets/rending/passport.png';
import point from '../assets/rending/point.png';
import menu from '../assets/common/menu.png';
import menuWhite from '../assets/common/menuWhite.png';
import upArrow from '../assets/common/upArrow.png';
import downArrow from '../assets/common/downArrow.png';

import coorOrder1 from '../assets/rending/cooperation/order1.png';
import coorOrder2 from '../assets/rending/cooperation/order2.png';
import coorOrder3 from '../assets/rending/cooperation/order3.png';
import coorOrder4 from '../assets/rending/cooperation/order4.png';
import coorOrder5 from '../assets/rending/cooperation/order5.png';
import coorOrder6 from '../assets/rending/cooperation/order6.png';
import coorOrder7 from '../assets/rending/cooperation/order7.png';
import coorOrder8 from '../assets/rending/cooperation/order8.png';
import coorOrder9 from '../assets/rending/cooperation/order9.png';
import coorOrder10 from '../assets/rending/cooperation/order10.png';

import receipt from '../assets/taxFree/receipt.png';
import shopping from '../assets/taxFree/shopping.png';
import discount from '../assets/taxFree/discount.png';

import product1 from '../assets/taxFree/product/product1.png';
import product2 from '../assets/taxFree/product/product2.png';
import product3 from '../assets/taxFree/product/product3.png';
import product4 from '../assets/taxFree/product/product4.png';
import product5 from '../assets/taxFree/product/product5.png';
import product6 from '../assets/taxFree/product/product6.png';
import product7 from '../assets/taxFree/product/product7.png';
import product8 from '../assets/taxFree/product/product8.png';
import product9 from '../assets/taxFree/product/product9.png';
import product10 from '../assets/taxFree/product/product10.png';
import product11 from '../assets/taxFree/product/product11.png';
import product12 from '../assets/taxFree/product/product12.png';
import product13 from '../assets/taxFree/product/product13.png';
import product14 from '../assets/taxFree/product/product14.png';
import product15 from '../assets/taxFree/product/product15.png';

import payment from '../assets/service/payment.png';
import arrow from '../assets/service/arrow.png';
import detail1 from '../assets/service/detail/detail1.png';
import detail2 from '../assets/service/detail/detail2.png';
import detail3 from '../assets/service/detail/detail3.png';
import detail4 from '../assets/service/detail/detail4.png';
import detail_e1 from '../assets/service/detail/detail_e1.png';
import detail_e2 from '../assets/service/detail/detail_e2.png';
import detail_e3 from '../assets/service/detail/detail_e3.png';
import korea from '../assets/service/korea.png';
import english from '../assets/service/english.png';
import pre from '../assets/service/pre.png';
import preActive from '../assets/service/pre_active.png';
import next from '../assets/service/next.png';
import nextActive from '../assets/service/next_active.png';
import security from '../assets/service/security.png';
import good from '../assets/service/good.png';
import setting from '../assets/service/setting.png';

import checkBox from '../assets/common/checkbox.png';
import checkBoxActive from '../assets/common/checkbox_active.png';
import close from '../assets/common/close.png';
import check from '../assets/common/check.png';

import logoGuide from '../assets/guide/design/logo_guide.png';
import logoUseGuide1 from '../assets/guide/design/logo_useGuide1.png';
import logoUseGuide2 from '../assets/guide/design/logo_useGuide2.png';
import buttonGuide from '../assets/guide/design/button_guide.png';
import buttonDisable from '../assets/guide/design/button_disable.png';
import buttonSize from '../assets/guide/design/button_size.png';
import buttonApply from '../assets/guide/design/button_apply.png';
import buttonUse1 from '../assets/guide/design/button_use1.png';
import buttonUse2 from '../assets/guide/design/button_use2.png';
import buttonUse3 from '../assets/guide/design/button_use3.png';
import buttonUse4 from '../assets/guide/design/button_use4.png';

import summaryProcess from '../assets/guide/develop/summary_process.png';
import summaryFlow from '../assets/guide/develop/summary_flow.png';
import tokenExplain from '../assets/guide/develop/token_explain.png';

const coorOrders = [
  { id: 1, orderImg: coorOrder1 },
  { id: 2, orderImg: coorOrder2 },
  { id: 3, orderImg: coorOrder3 },
  { id: 4, orderImg: coorOrder4 },
  { id: 5, orderImg: coorOrder5 },
  { id: 6, orderImg: coorOrder6 },
  { id: 7, orderImg: coorOrder7 },
  { id: 8, orderImg: coorOrder8 },
  { id: 9, orderImg: coorOrder9 },
  { id: 10, orderImg: coorOrder10 },
  { id: 11, orderImg: coorOrder1 },
  { id: 12, orderImg: coorOrder2 },
  { id: 13, orderImg: coorOrder3 },
  { id: 14, orderImg: coorOrder4 },
  { id: 15, orderImg: coorOrder5 },
  { id: 16, orderImg: coorOrder6 },
  { id: 17, orderImg: coorOrder7 },
  { id: 18, orderImg: coorOrder8 },
  { id: 19, orderImg: coorOrder9 },
  { id: 20, orderImg: coorOrder10 },
];

const coorOrdersMobileFirst = [
  { id: 1, orderImg: coorOrder1 },
  { id: 2, orderImg: coorOrder2 },
  { id: 3, orderImg: coorOrder3 },
  { id: 4, orderImg: coorOrder4 },
  { id: 5, orderImg: coorOrder5 },
  { id: 6, orderImg: coorOrder6 },
  { id: 7, orderImg: coorOrder7 },
  { id: 8, orderImg: coorOrder8 },
  { id: 9, orderImg: coorOrder9 },
  { id: 10, orderImg: coorOrder10 },
  { id: 11, orderImg: coorOrder1 },
  { id: 12, orderImg: coorOrder2 },
  { id: 13, orderImg: coorOrder3 },
  { id: 14, orderImg: coorOrder4 },
  { id: 15, orderImg: coorOrder5 },
  { id: 16, orderImg: coorOrder6 },
  { id: 17, orderImg: coorOrder7 },
  { id: 18, orderImg: coorOrder8 },
  { id: 19, orderImg: coorOrder9 },
  { id: 20, orderImg: coorOrder10 },
];

const coorOrdersMobileSecond = [
  { id: 1, orderImg: coorOrder7 },
  { id: 2, orderImg: coorOrder8 },
  { id: 3, orderImg: coorOrder9 },
  { id: 4, orderImg: coorOrder10 },
  { id: 5, orderImg: coorOrder1 },
  { id: 6, orderImg: coorOrder2 },
  { id: 7, orderImg: coorOrder3 },
  { id: 8, orderImg: coorOrder4 },
  { id: 9, orderImg: coorOrder5 },
  { id: 10, orderImg: coorOrder6 },
  { id: 11, orderImg: coorOrder7 },
  { id: 12, orderImg: coorOrder8 },
  { id: 13, orderImg: coorOrder9 },
  { id: 14, orderImg: coorOrder10 },
  { id: 15, orderImg: coorOrder1 },
  { id: 16, orderImg: coorOrder2 },
  { id: 17, orderImg: coorOrder3 },
  { id: 18, orderImg: coorOrder4 },
  { id: 19, orderImg: coorOrder5 },
  { id: 20, orderImg: coorOrder6 },
];

const businessProducts = [
  { id: 1, name: '호텔 / 숙박', productImg: product1 },
  { id: 2, name: '성형 / 의료', productImg: product2 },
  { id: 3, name: '미용', productImg: product3 },
  { id: 4, name: '약국', productImg: product4 },
  { id: 5, name: '의류', productImg: product5 },
  { id: 6, name: '신발', productImg: product6 },
  { id: 7, name: '건강식품', productImg: product7 },
  { id: 8, name: '화장품', productImg: product8 },
  { id: 9, name: '가전제품', productImg: product9 },
  { id: 10, name: '전자기기', productImg: product10 },
  { id: 11, name: '쥬얼리', productImg: product11 },
  { id: 12, name: '안경', productImg: product12 },
  { id: 13, name: '기념품', productImg: product13 },
  { id: 14, name: '패션잡화', productImg: product14 },
  { id: 15, name: '시계', productImg: product15 },
];

const detailKorea = [detail1, detail2, detail3, detail4];
const detailEnglish = [detail_e1, detail2, detail_e2, detail_e3];

export {
  upArrow,
  downArrow,
  logo,
  logoWhite,
  developerHome,
  graphHome,
  coorOrders,
  customs,
  passport,
  point,
  receipt,
  businessProducts,
  shopping,
  discount,
  payment,
  arrow,
  detailKorea,
  detailEnglish,
  korea,
  english,
  pre,
  preActive,
  next,
  nextActive,
  security,
  setting,
  good,
  checkBox,
  checkBoxActive,
  close,
  check,
  logoGuide,
  logoUseGuide1,
  logoUseGuide2,
  buttonGuide,
  buttonDisable,
  buttonSize,
  buttonApply,
  buttonUse1,
  buttonUse2,
  buttonUse3,
  buttonUse4,
  menu,
  menuWhite,
  coorOrdersMobileFirst,
  coorOrdersMobileSecond,
  summaryProcess,
  summaryFlow,
  tokenExplain,
};
