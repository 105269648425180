import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { serviceDetailContent } from '../../constants/serviceContent';
import { next, nextActive, pre, preActive } from '../../util/getImages';

function DetailMobileProcess() {
  const [isKorea, setIsKorea] = useState(true);
  const [step, setStep] = useState(0);
  const processesRef = useRef<HTMLDivElement | null>(null);
  const { processTitle, process, korea, english } = serviceDetailContent;

  useEffect(() => {
    processesRef.current!.style.transform = `translateX(0px)`;
  }, [isKorea]);

  const changeWorld = (check: boolean) => {
    setIsKorea(check);
    setStep(0);
  };

  const movePre = () => {
    if (step === 0) {
      return;
    }
    setStep((prev) => prev - 1);
    processesRef.current!.style.transform = `translateX(calc(-250px * ${
      step - 1
    }))`;
  };

  const moveNext = () => {
    if (step === 3) {
      return;
    }
    setStep((prev) => prev + 1);
    processesRef.current!.style.transform = `translateX(calc(-250px * ${
      step + 1
    }))`;
  };

  return (
    <Container>
      <Header>
        <Title>{processTitle}</Title>
        <WorldImageContainer>
          <WorldImage
            src={korea.world}
            isKorea={isKorea}
            onClick={() => changeWorld(true)}
          />
          <WorldImage
            src={english.world}
            isKorea={!isKorea}
            onClick={() => changeWorld(false)}
          />
        </WorldImageContainer>
      </Header>
      <Detail>
        <ArrowButton src={step === 0 ? pre : preActive} onClick={movePre} />
        <ProcessWrapper>
          <ProcessContainer ref={processesRef}>
            {(isKorea ? korea : english).img.map((item, i) => (
              <Process key={i}>
                <ProcessImage src={item} />
                <ProcessText>
                  <span>{i + 1}</span>
                  {process[i]}
                </ProcessText>
              </Process>
            ))}
          </ProcessContainer>
          <IndicatorsContainer>
            <Indicator active={step === 0} />
            <Indicator active={step === 1} />
            <Indicator active={step === 2} />
            <Indicator active={step === 3} />
          </IndicatorsContainer>
        </ProcessWrapper>
        <ArrowButton src={step === 3 ? next : nextActive} onClick={moveNext} />
      </Detail>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 64px 58px 64px;
  background: ${(props) => props.theme.blueColor.scale1};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 248px;
`;

const Title = styled.h3`
  font-weight: ${(props) => props.theme.fontWight.bold};
  font-size: ${(props) => props.theme.mobileFontSize.medium};
  line-height: ${(props) => props.theme.mobileLineHeight.medium};
  flex: 1;
`;

const WorldImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-bottom: 8px;
`;

const WorldImage = styled.img<{ isKorea: boolean }>`
  width: 30px;
  height: 30px;
  opacity: ${(props) => (props.isKorea ? 1 : 0.3)};
  object-fit: contain;
`;

const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ArrowButton = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  cursor: pointer;
`;

const ProcessWrapper = styled.div`
  width: 250px;
  height: 650px;
  overflow: hidden;
`;

const ProcessContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 1000px;
`;

const Process = styled.div``;

const ProcessImage = styled.img`
  width: 250px;
  height: 520px;
  object-fit: contain;
`;

const ProcessText = styled.div`
  margin-top: 32px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  text-align: center;
  span {
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    color: ${(props) => props.theme.tintColor.white};
    background: ${(props) => props.theme.grayScaleColor.grayScale1};
  }
`;

const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  gap: 8px;
`;

const Indicator = styled.div<{ active: boolean }>`
  display: flex;
  background: ${(props) =>
    props.active ? props.theme.tintColor.primary : '#B3B3B3'};
  border-radius: 5px;
  width: 8px;
  height: 8px;
`;

export default DetailMobileProcess;
