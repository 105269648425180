import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ServiceFlowContent } from '../../../constants/developeGuideContent';
import { summaryFlow } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ServiceFlow({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive, 0.3);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='serviceFlow'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        서비스 흐름(workflow)
      </Title>
      <ImageContainer>
        <Image src={summaryFlow} />
      </ImageContainer>
      <Contents>
        {ServiceFlowContent.map((item) => (
          <Content key={item.id}>{item.content}</Content>
        ))}
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 122px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    scroll-margin-top: 155px;
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Image = styled.img`
  width: 860px;
  height: 1150px;
  object-fit: contain;
`;

const Contents = styled.ul`
  margin-top: 16px;
  @media ${(props) => props.theme.mobile} {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 16px;
  }
`;

const Content = styled.li`
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default ServiceFlow;
