import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../router/routes';

const RendingHomeButton = () => {
  return (
    <ButtonContainer>
      <Button>
        <Link to={routes.inquiry} style={{ color: '#246CF6' }}>
          도입 문의
        </Link>
      </Button>
      <Button colored>
        <Link to={routes.service}>서비스 소개</Link>
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  @media ${(props) => props.theme.mobile} {
    margin: 160px 0 40px;
    width: 100%;
  }
`;

const Button = styled.button<{ colored?: boolean }>`
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  background: ${(props) => (props.colored ? '#246CF6' : '#FFF')};
  :hover {
    opacity: 0.9;
  }
  @media ${(props) => props.theme.desktop} {
    width: 148px;
    height: 44px;
  }
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    height: 40px;
    :active {
      opacity: 0.8;
    }
  }
`;

export default RendingHomeButton;
