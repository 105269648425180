import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CallingContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Calling({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='calling'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        호출
      </Title>
      <Contents>
        {CallingContent.map((item) => (
          <Content key={item.id}>{item.content}</Content>
        ))}
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 125px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    scroll-margin-top: 150px;
  }
`;

const Contents = styled.ul`
  @media ${(props) => props.theme.mobile} {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const Content = styled.li`
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    padding-right: 16px;
  }
`;

export default Calling;
