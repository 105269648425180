import React from 'react';
import styled from 'styled-components';

import { GlobalLayoutProps } from '../Helmet/HelmetComponet';

function Layout({ children }: GlobalLayoutProps) {
  return (
    <LayoutContainer>
      <Main>{children}</Main>
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  background: ${(props) => props.theme.tintColor.gradient};
  @media ${(props) => props.theme.desktop} {
    min-width: 1400px;
  }
  /* overflow: hidden; */
`;

const Main = styled.main`
  position: relative;
`;

export default Layout;
