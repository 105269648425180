import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  SideNavigationBarDesignContent,
  SideNavigationBarDevelopContent,
} from '../../constants/designGudieContent';
import SideNavigationItem from './SideNavigationItem';

export interface IPage {
  id: number;
  title: string;
  link: string;
  active: boolean;
  list?: IList[];
}

interface IList {
  id: number;
  title: string;
  link: string;
}

function SideNavigationBar() {
  const { pathname: curUrl } = useLocation();
  const pageInfo = curUrl.includes('design')
    ? SideNavigationBarDesignContent
    : SideNavigationBarDevelopContent;

  return (
    <SideContainer>
      <PageList>
        {pageInfo.map((page: IPage) => (
          <SideNavigationItem key={page.id} pageItem={page} />
        ))}
      </PageList>
    </SideContainer>
  );
}

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding: 0px 24px;
  border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
`;

const PageList = styled.ul`
  position: sticky;
  top: 108px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 54px;
`;

export default SideNavigationBar;
