import React from 'react';
import styled from 'styled-components';

import MainFunctionContent from './MainFunctionContent';

function RendingMainFunction() {
  return (
    <Wrapper>
      <Container>
        <Title>KTP 주요 기능</Title>
        <MainFunctionContent />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.white};
  padding: 180px;
  @media ${(props) => props.theme.mobile} {
    padding: 96px 5px 144px 5px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 80px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-bottom: 48px;
  }
`;

export default RendingMainFunction;
