import React from 'react';
import styled from 'styled-components';

function ErrorGraph({ content }: any) {
  return (
    <Container>
      <Head>
        {content.head.map((item: any) => (
          <HeadContent key={item.id} isLong={item.isLong}>
            {item.title}
          </HeadContent>
        ))}
      </Head>
      <Contents>
        {content.content.map((item: any) => (
          <Content key={item.id} isHeight={item.isHeight}>
            <ContentItem>{item.division}</ContentItem>
            <ContentItem>{item.code}</ContentItem>
            <ContentItem isLong>{item.explain}</ContentItem>
          </Content>
        ))}
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  width: 900px;
`;

const Head = styled.div`
  display: flex;
`;

const HeadContent = styled.div<{ isLong?: boolean }>`
  flex: ${(props) => (props.isLong ? 4 : 1)};
  padding: 12px 0px 12px 30px;
  border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  :last-child {
    border-right: none;
  }
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

const Contents = styled.div``;

const Content = styled.div<{ isHeight?: boolean }>`
  display: flex;
  align-items: center;
  height: ${(props) => (props.isHeight ? '96px' : '48px')};
`;

const ContentItem = styled.div<{ isLong?: boolean }>`
  flex: ${(props) => (props.isLong ? 4 : 1)};
  height: 100%;
  white-space: pre-line;
  padding: 12px 0px 12px 30px;
  border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  border-bottom: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  :last-child {
    border-right: none;
  }
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

export default ErrorGraph;
