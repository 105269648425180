import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { serviceDetailContent } from '../../constants/serviceContent';
import { next, nextActive, pre, preActive } from '../../util/getImages';

function DetailProcess() {
  const [isKorea, setIsKorea] = useState(true);
  const { processTitle, process, korea, english } = serviceDetailContent;
  const processRef = useRef<HTMLDivElement | null>(null);

  // isKorea의 상태에 따라 transform을 하여 dom을 이동 시킴(슬라이드)
  const moveProcess = () => {
    if (isKorea) {
      processRef.current!.style.transform = 'translateX(calc(-100% - 12px))';
      setIsKorea(false);
    } else {
      processRef.current!.style.transform = 'translateX(0)';
      setIsKorea(true);
    }
  };

  return (
    <Container>
      <Header>
        <Title>{processTitle}</Title>
        <WorldImage src={isKorea ? korea.world : english.world} />
      </Header>
      <Detail>
        <ArrowButton
          src={isKorea ? pre : preActive}
          onClick={moveProcess}
          style={{ pointerEvents: isKorea ? 'none' : 'auto' }}
        />
        <ProcessWrapper>
          <ProcessContainer ref={processRef}>
            {korea.img.map((item, i) => (
              <Process key={i}>
                <ProcessImage src={item} />
                <ProcessText>
                  <span>{i + 1}</span>
                  {process[i]}
                </ProcessText>
              </Process>
            ))}
            {english.img.map((item, i) => (
              <Process key={i}>
                <ProcessImage src={item} />
                <ProcessText>
                  <span>{i + 1}</span>
                  {process[i]}
                </ProcessText>
              </Process>
            ))}
          </ProcessContainer>
        </ProcessWrapper>
        <ArrowButton
          src={isKorea ? nextActive : next}
          onClick={moveProcess}
          style={{ pointerEvents: isKorea ? 'auto' : 'none' }}
        />
      </Detail>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 12px;
  background: ${(props) => props.theme.blueColor.scale1};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Title = styled.h3`
  margin-left: 30px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.medium};
`;

const WorldImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 42px;
  object-fit: contain;
`;

const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ArrowButton = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  cursor: pointer;
`;

const ProcessWrapper = styled.div`
  overflow-x: hidden;
`;

const ProcessContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  transition: all 300ms ease;
`;

const Process = styled.div``;

const ProcessImage = styled.img`
  width: 250px;
  height: 540px;
  object-fit: contain;
`;

const ProcessText = styled.div`
  margin-top: 24px;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  span {
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    color: ${(props) => props.theme.tintColor.white};
    background: ${(props) => props.theme.grayScaleColor.grayScale1};
  }
`;

export default DetailProcess;
