import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RefundCancleRequestContent } from '../../../../constants/developeGuideContent';
import { isMobile } from '../../../../util/isMobile';
import { getIntersectionObserver } from '../../../../util/observer';
import DevelopGraph from '../../DevelopGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Request({ setCurrActive }: IProps) {
  const {
    graph: { head, content },
    example,
  } = RefundCancleRequestContent;

  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='refundCancleRequest'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        Request
      </Title>
      <Contents>
        <Content>Method : POST</Content>
        <Content>
          MediaType :{' '}
          <Bold padding={isMobile() ? '0px 14.5px' : '2px 10.5px'}>
            application/json
          </Bold>
        </Content>
        <Content>
          Charset :{' '}
          <Bold padding={isMobile() ? '0px 17.5px' : '2px 29.5px'}> UTF-8</Bold>
        </Content>
        <Content style={{ marginTop: '12px' }}>
          <p style={{ marginBottom: '25px' }}>Specification</p>
          <DevelopGraph head={head} content={content} />
        </Content>

        <Content style={{ marginTop: '12px' }}>
          <p style={{ marginBottom: '25px' }}>Example</p>
          <ExampleContainer>
            <ExampleContent>{example}</ExampleContent>
          </ExampleContainer>
        </Content>
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 100px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Content = styled.div``;

const Bold = styled.span<{ padding?: string }>`
  padding: ${(props) => (props.padding ? props.padding : '0px 14px')};
  border-radius: 8px;
  text-align: center;
  background: ${(props) => props.theme.tintColor.primaryVarient};
  color: ${(props) => props.theme.tintColor.primary};
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const ExampleContainer = styled.div`
  white-space: pre-wrap;
  padding: 20px;
  border-radius: 12px;
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  font-size: ${(props) => props.theme.fontSize.light};
  line-height: ${(props) => props.theme.lineHeight.regular};
  font-family: monospace;
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const ExampleContent = styled.p`
  width: ${document.body.offsetWidth}px;
  white-space: pre-wrap;
`;

export default Request;
