import styled from 'styled-components';
import { isMobile } from '../util/isMobile';

const Content = styled.span<{ bold?: boolean; width?: string }>`
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) =>
    props.bold
      ? props.theme.tintColor.primary
      : props.theme.grayScaleColor.grayScale1};
  background: ${(props) => props.bold && props.theme.tintColor.primaryVarient};
  font-weight: ${(props) => props.bold && props.theme.fontWight.regular};
  display: ${(props) => props.bold && 'inline-block'};
  width: ${(props) => props.bold && '110px'};
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.bold && '28px'};
  border-radius: ${(props) => props.bold && '8px'};
  text-align: ${(props) => props.bold && 'center'};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    width: ${(props) => props.bold && '80px'};
    width: ${(props) => props.width && props.width};
    height: ${(props) => props.bold && '24px'};
  }
`;

const Bold = styled.span<{ padding?: string }>`
  padding: ${(props) => (props.padding ? props.padding : '0px 14px')};
  border-radius: 8px;
  text-align: center;
  background: ${(props) => props.theme.tintColor.primaryVarient};
  color: ${(props) => props.theme.tintColor.primary};
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const IndexDevelopListContent = {
  summary: [
    {
      id: 1,
      content: '1. 서비스 이용 신청 절차',
      active: false,
      checkId: 'summary',
    },
    {
      id: 2,
      content: '2. 키 발급 받기',
      active: false,
      checkId: 'keyAccept',
    },
    {
      id: 3,
      content: '3. 서비스 흐름(workflow)',
      active: false,
      checkId: 'serviceFlow',
    },
    {
      id: 4,
      content: '4. 호출',
      active: false,
      checkId: 'calling',
    },
    {
      id: 5,
      content: '5. API Data 결과',
      active: false,
      checkId: 'apiData',
    },
    {
      id: 6,
      content: '6. Result Data',
      active: false,
      checkId: 'resultData',
    },
  ],
  token: [
    {
      id: 1,
      content: '1. 설명',
      active: false,
      checkId: 'tokenExplain',
    },
    {
      id: 2,
      content: '2. End point',
      active: false,
      checkId: 'tokenEndPoint',
    },
    {
      id: 3,
      content: '3. Request',
      active: false,
      checkId: 'tokenRequest',
    },
    {
      id: 4,
      content: '4. Response',
      active: false,
      checkId: 'tokenResponse',
    },
  ],
  error: [
    {
      id: 1,
      content: '1. 기본 요청 오류',
      active: false,
      checkId: 'errorBasic',
    },
    {
      id: 2,
      content: '2. Authentication, Authorization',
      active: false,
      checkId: 'errorAuth',
    },
    {
      id: 3,
      content: '3. Refund',
      active: false,
      checkId: 'errorRefund',
    },
  ],
  refund: [
    {
      id: 1,
      content: '1. 환급가능 및 한도조회',
      active: false,
      checkId: 'refundInquiryBasic',
    },
    {
      id: 2,
      content: '2. End point',
      active: false,
      checkId: 'refundInquiryEndPoint',
    },
    {
      id: 3,
      content: '3. Request',
      active: false,
      checkId: 'refundInquiryRequest',
    },
    {
      id: 4,
      content: '4. Respone',
      active: false,
      checkId: 'refundInquiryResponse',
    },
  ],
  refundApply: [
    {
      id: 1,
      content: '1. 환급 승인 및 완료 절차',
      active: false,
      checkId: 'refundProcessSuccess',
    },
    {
      id: 2,
      content: '2. 환급 요청 및 승인 절차',
      active: false,
      checkId: 'refundApplyProcess',
    },
    {
      id: 3,
      content: '3. End point',
      active: false,
      checkId: 'refundApplyEndPoint',
    },
    {
      id: 4,
      content: '4. Request',
      active: false,
      checkId: 'refundApplyRequest',
    },
    {
      id: 5,
      content: '5. Respone',
      active: false,
      checkId: 'refundApplyResponse',
    },
  ],
  refundCancle: [
    {
      id: 1,
      content: '1. 환급 취소 절차',
      active: false,
      checkId: 'refundCancleProcess',
    },
    {
      id: 2,
      content: '2. End point',
      active: false,
      checkId: 'refundCancleEndPoint',
    },
    {
      id: 3,
      content: '3. Request',
      active: false,
      checkId: 'refundCancleRequest',
    },
    {
      id: 4,
      content: '4. Response',
      active: false,
      checkId: 'refundCancleResponse',
    },
  ],
};

const DevelopGraphContent = {
  head: [
    { id: 1, head: '명칭' },
    { id: 2, head: '설명' },
  ],
  keyAccept: [
    {
      id: 1,
      name: 'publicKey',
      content: (
        <>
          <Content>
            {'RSA 암호화가 필요한 API 가 있습니다. 키 관리에서 확인할 수 있는 '}
          </Content>
          <Content bold>{'publicKey'}</Content>
          <Content>
            {
              ' 로 RSA\n암호화를 하면 됩니다. RSA 암호화는 계정 정보 등록, 빠른 계좌 거래내역 등에서 사용합니다.'
            }
          </Content>
        </>
      ),
    },
    {
      id: 2,
      name: 'clientId',
      content: (
        <>
          <Content>{'CODEF API 인증에 필수인 '}</Content>
          <Content bold>{'acessToken'}</Content>
          <Content>{' 을 발급하기 위해 필요한 것입니다.'}</Content>
        </>
      ),
    },
    {
      id: 3,
      name: 'clientSecret',
      content: (
        <>
          <Content>{'CODEF API 인증에 필수인 '}</Content>
          <Content bold>{'acessToken'}</Content>
          <Content>{' 을 발급하기 위해 필요한 것입니다.'}</Content>
        </>
      ),
    },
  ],
};

const ServiceFlowContent = [
  {
    id: 1,
    content:
      '- 본 연동 매뉴얼에 설명하는 API는 서버에서 호출하는 API로 사용자에게 보여지는 디자인이 되어있지 않습니다.',
  },
  {
    id: 2,
    content: (
      <>
        <Content>{'- 웹사이트 '}</Content>
        <Content bold width={isMobile() ? '150px' : '192px'}>
          {'openapi.ktaxpay.com'}
        </Content>
        <Content>
          {' 의 REST URL 페이지를 호출해서 응답 값을 받아 연동합니다.'}
        </Content>
      </>
    ),
  },
  {
    id: 3,
    content: '- URL 페이지 호출은 서버에서 실행이 되어야 합니다.',
  },
  {
    id: 4,
    content: '- URL 주소 : 예시) https://api.ktxpay.com/v1/authentication',
  },
];

const CallingContent = [
  {
    id: 1,
    content:
      '- 호출 파라메터는 FORM POST 방식으로 REST API URL 주소에 전달합니다.',
  },
  {
    id: 2,
    content: '- 호출 파라메터의 각 값은 UTF-8로 인코딩된 문자열 이어야 합니다.',
  },
  {
    id: 3,
    content:
      '- 암호화가 필요한 데이터는 public key를 통해 암호화를 해서 전달합니다.',
  },
];

const ApiDataContent = [
  {
    id: 1,
    content: '- 일반적으로 공통 + 요청데이터',
  },
  {
    id: 2,
    content:
      '- 공통에는 결과 코드, 메시지 등이 포함되고, 데이터에는 api마다 고유한 데이터들이 포함되게 구성하였습니다.',
  },
  {
    id: 3,
    content:
      '- API 호출 및 결과 응답 시의 데이터는 JSON(JavaScript Object Notation) 포맷으로 처리됩니다.',
  },
];

const ResultDataContent = [
  {
    id: 1,
    content: (
      <>
        <Content>{'모든 요청의 결과는 '}</Content>
        <Content bold>{'common'}</Content>
        <Content>{' 와 '}</Content>
        <Content bold>{'result'}</Content>
        <Content>{' 로 정의된 키를 갖습니다. '}</Content>
        <Content bold>{'common'}</Content>
        <Content>
          {' 에는 모든 API의 성공 여부 및 에러코드 등의 공통 데이터가 담기고, '}
        </Content>
        <Content bold>{'result'}</Content>
        <Content>
          {' 에는 요청한 API에 대한 실제 데이터가 담기게 됩니다.'}
        </Content>
      </>
    ),
    graph: {
      head: [
        { id: 1, head: 'Key' },
        { id: 2, head: 'Name' },
        { id: 3, head: 'Type' },
        { id: 4, head: 'Required' },
        { id: 5, head: 'Description' },
      ],
      content: [
        {
          id: 1,
          name: 'common',
          content: {
            name: 'API 호출 공통',
            type: 'Obejct',
            required: 'O',
            description: '',
          },
        },
        {
          id: 2,
          name: 'result',
          content: {
            name: 'API 결과 데이터',
            type: 'Obejct',
            required: 'O',
            description: '',
          },
        },
      ],
    },
  },
  {
    id: 2,
    subTitle: 'Common',
    content: '모든 API 공통 결과입니다.',
    graph: {
      head: [
        { id: 1, head: 'Key' },
        { id: 2, head: 'Name' },
        { id: 3, head: 'Type' },
        { id: 4, head: 'Required' },
        { id: 5, head: 'Description' },
      ],
      content: [
        {
          id: 1,
          name: 'code',
          content: {
            name: '결과코드',
            type: 'String',
            required: 'O',
            description: '',
          },
        },
        {
          id: 2,
          name: 'message',
          content: {
            name: '메세지',
            type: 'String',
            required: 'O',
            description: '메세지',
          },
        },
        {
          id: 3,
          name: 'message',
          content: {
            name: '트랜잭션 시간',
            type: 'String',
            required: 'O',
            description: '',
          },
        },
      ],
    },
  },
  {
    id: 3,
    subTitle: 'Result',
    content: '각 api의 Response의 specification의 결과가 담깁니다.',
    code: [
      {
        id: 1,
        key: '"common"',
        value: [
          { key: '"code"', value: '"0000"' },
          { key: '"message"', value: '"성공"' },
          { key: '"time"', value: '"2022-10-30 12:22:30.123"' },
        ],
      },
      {
        id: 2,
        key: '"result"',
        value: [
          { key: '"access_token"', value: '" "' },
          { key: '"token_type"', value: '"Bearer"' },
          { key: '"expires_in"', value: '"604800"' },
          { key: '"scope"', value: '"refund"' },
        ],
      },
    ],
  },
];

const tokenExplainContent = {
  title: '설명',
  content: (
    <>
      <Content bold>{'clientId'}</Content>
      <Content>{' 와 '}</Content>
      <Content bold>{'clientSecret'}</Content>
      <Content>{' 으로 '}</Content>
      <Content bold>{'clientSecret'}</Content>
      <Content>
        {
          ' 이 발급되면 클라이언트 등록이 완료됩니다. 토큰을 발행한 이후의 모든\nAPI를 호출하기 위해서는 발급된 '
        }
      </Content>
      <Content bold>{'access_token'}</Content>
      <Content>{' 을 HTTP 헤더에 포함하여 요청해야 합니다. 발급된 '}</Content>
      <Content bold>{'access_token'}</Content>
      <Content>
        {' 정보를\nAuthorization Header에 포함하여 요청을 진행해야 합니다.\n\n'}
      </Content>
      <Content>{' (표기 예시) Authorization : Bearer( '}</Content>
      <Content bold>{'token_type'}</Content>
      <Content>{" ) + ' ' + "}</Content>
      <Content bold>{'access_token'}</Content>
      <Content>{'\n\n'}</Content>
      <Content bold>{'access_token'}</Content>
      <Content>
        {
          ' 은 일주일간 유효하므로 데이터베이스나 글로벌 변수에 저장하여 재사용하는 것을 권장합니다. '
        }
      </Content>
      <Content bold>{'access_token'}</Content>
      <Content>
        {
          ' 이 만료\n재발급이 필요하며, 만료 시에 재발급하도록 로직 구현 바랍니다. '
        }
      </Content>
    </>
  ),
};

const TokenRequestContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      { id: 1, content: 'Specification' },
      { id: 2, content: 'key' },
      { id: 3, content: 'String' },
      { id: 4, content: 'O' },
      { id: 5, content: 'clientid + “:” + clientSecret 을 Base64 인코딩한 값' },
    ],
  },
};

const TokenResponseContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Body',
        content: {
          name: 'accessToken',
          type: 'String',
          required: 'O',
          description: '발행된 access token',
        },
      },
      {
        id: 2,
        name: 'Body',
        content: {
          name: 'tokenType',
          type: 'String',
          required: 'O',
          description: 'Bearer 고정 값',
        },
      },
      {
        id: 3,
        name: 'Body',
        content: {
          name: 'expires',
          type: 'String',
          required: 'O',
          description: '토큰 만료시간(초)',
        },
      },
      {
        id: 4,
        name: 'Body',
        content: {
          name: 'scope',
          type: 'String',
          required: 'O',
          description: 'Access token의 권한 범위',
        },
      },
    ],
  },
  code: [
    {
      id: 1,
      key: '"common"',
      value: [
        {
          key: '',
          value: '',
        },
      ],
    },
    {
      id: 2,
      key: '"result"',
      value: [
        {
          key: '"access_token"',
          value:
            '"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NGIjoxNDksImlhdCI6MTY2MDAzNz\ng0NiwiZXhwIjoxNjYwMTI0MjQ2fQ.zjg4oL1Lzel7fmp43BWqjpuYnI5ZtZe5qDAjikD9_Y0"',
        },
        { key: '"token_type"', value: '"Bearer"' },
        { key: '"expires_in"', value: '"604800"' },
        { key: '"scope"', value: '"refund"' },
      ],
    },
  ],
};

const ErrorGraphContent = {
  basic: {
    head: [
      { id: 1, title: '오류구분' },
      { id: 2, title: '오류코드' },
      { id: 3, title: '설명', isLong: true },
    ],
    content: [
      {
        id: 1,
        division: '기본',
        code: '000000',
        explain: '성공',
      },
      {
        id: 2,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 3,
        division: '',
        code: '000001',
        explain: '허용하는 IP 주소가 아닙니다. 관리자에게 문의하세요.',
      },
      {
        id: 4,
        division: '',
        code: '000002',
        explain: '요청의 형식이 잘못되어 Parse Error 가 발생했습니다.',
      },
      {
        id: 5,
        division: '',
        code: '000003',
        explain: '요청하신 API의 필수 파라미터가 존재하지 않습니다.',
      },
      {
        id: 6,
        division: '',
        code: '000004',
        explain: '요청하신 API의 필수 파라미터의 타입이 일치하지 않습니다.',
      },
      {
        id: 7,
        division: '',
        code: '000005',
        explain: 'PublicKey가 정상적으로 발급되지 못했습니다.',
      },
      {
        id: 8,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 9,
        division: '',
        code: '00403',
        explain: '요청하신 API 가 존재하지 않습니다.',
      },
      {
        id: 10,
        division: '',
        code: '00404',
        explain: '요청한 API의 결과를 찾을 수 없습니다.',
      },
      {
        id: 11,
        division: '',
        code: '00405',
        explain: '지원하지 않는 API 입니다.',
      },
      {
        id: 12,
        division: '',
        code: '00406',
        explain: '허용되지 않는 Scope 입니다.',
      },
      {
        id: 13,
        division: '',
        code: '99999',
        explain: '정의되지 않은 문제입니다. 서비스센터로 문의 부탁드립니다',
      },
    ],
  },
  auth: {
    head: [
      { id: 1, title: '오류구분' },
      { id: 2, title: '오류코드' },
      { id: 3, title: '설명', isLong: true },
    ],
    content: [
      {
        id: 1,
        division: '인증,인가',
        code: '01001',
        explain: 'Id가 올바르지 않습니다.',
      },
      {
        id: 2,
        division: '',
        code: '01002',
        explain: 'client id이 올바르지 않습니다.',
      },
      {
        id: 3,
        division: '',
        code: '01003',
        explain: 'clinet secret 이 올바르지 않습니다.',
      },
      {
        id: 4,
        division: '',
        code: '01004',
        explain:
          'Header에 Authorization Bearer access token이 존재하지 않습니다.',
      },
      {
        id: 5,
        division: '',
        code: '01005',
        explain: 'Access Token 정보가 올바르지 않습니다.',
      },
      {
        id: 6,
        division: '',
        code: '01006',
        explain: 'Access Token 만료, 재발급하시기 바랍니다.',
      },
      {
        id: 7,
        division: '',
        code: '01007',
        explain:
          '요청하신 API를 호출할 권한이 없습니다. 관리자에게 문의하세요.',
      },
    ],
  },
  refund: {
    head: [
      { id: 1, title: '오류구분' },
      { id: 2, title: '오류코드' },
      { id: 3, title: '설명', isLong: true },
    ],
    content: [
      {
        id: 1,
        division: '',
        code: '20012',
        explain: '구매일련번호로 찾을 수 있는 환급내역이 존재하지 않습니다.',
      },
      {
        id: 2,
        division: '',
        code: '20033',
        explain:
          '관세청에 매장등록이 정상적으로 진행되지 않았습니다. 고객센터에 문의하세요.',
      },
      {
        id: 3,
        division: '',
        code: '20048',
        explain: '입항정보가 없는 여권입니다.',
      },
      {
        id: 4,
        division: '',
        code: '20999',
        explain: '통신을 위한 데이터가 올바르지 않습니다.',
      },
      {
        id: 5,
        division: '',
        code: '21003',
        explain: '이미 취소된 물품 판매 번호입니다.',
      },
      {
        id: 6,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 7,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 8,
        division: '',
        code: '22001',
        explain:
          '입력된 물품들의 총 합산 금액이 1회 요청 최소한도보다 낮아 환급이 불가능합니다.',
      },
      {
        id: 9,
        division: '',
        code: '22002',
        explain:
          '입력된 물품들의 총 합산 금액이 1회 요청한도를 초과하여 환급이 불가능합니다.',
      },
      {
        id: 10,
        division: '',
        code: '22003',
        explain:
          '특정 원인 또는 무작위 추출로 인한 선별검사 대상자로 즉시환급이 불가능합니다',
      },
      {
        id: 11,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 12,
        division: '',
        code: '22011',
        explain: '이미 승인된 건입니다.',
      },
      {
        id: 13,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 14,
        division: '',
        code: '24001',
        explain: '하루 자동 환급이 가능한 건수를 초과했습니다. (20건 이상)',
      },
      {
        id: 15,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 16,
        division: '여권 검증 오류',
        code: '24001',
        explain:
          '이미 출국하거나 여권 정보가 올바르지 않습니다.\n(이중국적자의 경우 입국 시 제출한 국적을 입력해주세요.)',
        isHeight: true,
      },
      {
        id: 17,
        division: '',
        code: '24005',
        explain:
          '존재하지 않는 여권 혹은 내국인 여권은 즉시 환급을 적용할 수 없습니다.',
      },
      {
        id: 18,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 19,
        division: '세관 서버 오류',
        code: '27000',
        explain:
          '관세청 서버에 현재 문제가 발생하였습니다. 본사로 문의 부탁드립니다.',
      },
      {
        id: 20,
        division: '',
        code: '27012',
        explain: '데이터 암호화 오류입니다.',
      },
      {
        id: 21,
        division: '',
        code: '27013',
        explain: '데이터 복호화 오류입니다.',
      },
      {
        id: 22,
        division: '',
        code: '',
        explain: '',
      },
      {
        id: 23,
        division: 'API 송수신 오류',
        code: '29001',
        explain: '환급 절차가 정상적으로 처리되지 않았습니다.',
      },
      {
        id: 24,
        division: '',
        code: '29002',
        explain: '여권 번호에 대한 암호화가 올바르지 않습니다.',
      },
      {
        id: 25,
        division: '',
        code: '29003',
        explain: '이미 환급 확인이 완료된 주문입니다.',
      },
      {
        id: 26,
        division: '',
        code: '29004',
        explain: '환급 승인번호가 올바르지 않습니다.',
      },
      {
        id: 27,
        division: '',
        code: '29005',
        explain: '가맹점 구매일련번호에 해당하는 환급내역이 존재하지 않습니다.',
      },
      {
        id: 28,
        division: '',
        code: '29006',
        explain: '지원하지 않거나 잘못된 취소 타입입니다.',
      },
    ],
  },
};

const RefundInquiryBasicContent = {
  title: '환급가능 및 한도조회',
  subTitle: '환급가능 및 예상 환급금 절차',
};

const RefundInquiryExplainContent = {
  title: '설명',
  content: (
    <>
      <Content>
        {
          '관광객의 즉시 환급 할인 가능한 금액을 조회합니다. 현재 관세청 정책상 1회 입국 시 관광객의'
        }
      </Content>
      <Content bold width='268px'>
        {' 번에 신청할 수 있는 물품 금액 총합은 '}
      </Content>
      <Content bold width='234px'>
        {'최소 30,000원, 최대 500,000원'}
      </Content>
      <Content>
        {
          '입니다. 현재 관광객의 여권번호와 국적, 이름을 통해 관광객의 한도를 확인하여 환급 가능한지를 확인'
        }
      </Content>
      <Content>
        {'합니다. 환급 가능 및 한도조회에 대한 결과는 성공 시, '}
      </Content>
      <Content bold width='67px'>
        {'comon'}
      </Content>
      <Content>{' 과 함께 '}</Content>
      <Content bold width='67px'>
        {'result'}
      </Content>
      <Content>{' 내에 환급(즉시 할인) 예정 금액을 전달합니다.'}</Content>
    </>
  ),
};

const RefundInquiryRequestContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'application/json',
        content: {
          name: 'Authorization',
          type: 'String',
          required: 'O',
          description: 'Bearer <access_token>',
        },
      },
      {
        id: 2,
        name: 'Body',
        content: {
          name: 'name',
          type: 'String',
          required: 'O',
          description: '여권 소유자 이름',
        },
      },
      {
        id: 3,
        name: 'Body',
        content: {
          name: 'passport',
          type: 'String',
          required: 'O',
          description: 'Public key로 RSA 암호화된 여권 번호',
        },
      },
      {
        id: 4,
        name: 'Body',
        content: {
          name: 'nation',
          type: 'String',
          required: 'O',
          description: '여권 소유자 국가',
        },
      },
      {
        id: 5,
        name: 'Body',
        content: {
          name: 'price',
          type: 'String',
          required: 'O',
          description: '구매 품목 총 합',
        },
      },
    ],
  },
  example: `curl -v -X POST "https://openapi.ktaxpay.com/v1/refund/limit" \\ \n-H "Authorization: <access_token>"\n-H "Content-Type: application/json"\n-d @<(cat <<EOF\n{\n  "name": "TEST_USER",\n  "passport": "PublicKey로 암호화된 여권번호",\n  "nation": "GBR",\n  "price": "400000"\n}\nEOF\n)`,
};

const RefundInquiryReponseContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Common body',
        content: {
          name: 'common',
          type: 'array(JSON)',
          required: 'O',
          description: '성공 실패 여부',
        },
      },
      {
        id: 2,
        name: 'Result body',
        content: {
          name: 'discount',
          type: 'String',
          required: 'O',
          description: '환급(할인) 예정 금액',
        },
      },
    ],
  },
  code: [
    {
      id: 1,
      key: '"common"',
      value: [
        {
          key: '',
          value: ',',
        },
      ],
    },

    {
      id: 2,
      key: '"result"',
      value: [
        {
          key: '"discount"',
          value: '"26000"',
        },
      ],
    },
  ],
};

const RefundApplyExplainContent = {
  explain: (
    <Content>
      한도 조회가 성공한 대상에 대한 환급을 요청합니다. 요청을 위한 스펙은
      한도조회와 동일합니다. 결과는 환급에 대한 성공여부인 <Bold>common</Bold>
      &nbsp; 과 상세 내용인 <Bold>result</Bold> 로 전달됩니다. 한도조회가 성공한
      대상에 대해 대부분 승인은 허가되지만, 관세청의 랜덤추출 또는 기타원인으로
      인해 실패가 발생할 수 있습니다. 환급승인 요청의 결과로 전달되는{' '}
      <Bold>refundNumber</Bold> 는 이후 진행되는 환급 완료 확인 요청에 포함해서
      보내줘야 합니다. 환급 완료 확인 요청은 구매가 완료된 시점에 반드시
      실행되어야합니다.
    </Content>
  ),
  endpoint: (
    <Content>
      End point : <Bold>https://openapi.ktaxpay.com/v1/refund</Bold>
    </Content>
  ),
};

const RefundApplyRequestContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Header',
        content: {
          name: 'Authorization',
          type: 'String',
          required: 'O',
          description: 'Bearer <access_token>',
        },
      },
      {
        id: 2,
        name: 'Body',
        content: {
          name: 'name',
          type: 'String',
          required: 'O',
          description: '여권 소유자 이름',
        },
      },
      {
        id: 3,
        name: 'Body',
        content: {
          name: 'passport',
          type: 'String',
          required: 'O',
          description: 'Public key로 RSA 암호화된 여권 번호',
        },
      },
      {
        id: 4,
        name: 'Body',
        content: {
          name: 'nation',
          type: 'String',
          required: 'O',
          description: '여권 소유자 국가',
        },
      },
      {
        id: 5,
        name: 'Body',
        content: {
          name: 'price',
          type: 'String',
          required: 'O',
          description: '구매가격',
        },
      },
    ],
  },
  example: `curl -v -X POST "https://openapi.ktaxpay.com/v1/refund/limit" \\ \n-H "Authorization: <access_token>" \\\n-H "Content-Type: application/json" \\\n-d @<(cat <<EOF\n{\n  "name": "TEST_USER",\n  "passport": "PublicKey로 암호화된 여권번호",\n  "nation": "GBR",\n  "price": "400000"\n}\nEOF\n)`,
};

const RefundApplyReponseContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Common body',
        content: {
          name: 'common',
          type: 'array(JSON)',
          required: 'O',
          description: '성공 실패 여부',
        },
      },
    ],
  },
  code: [
    {
      id: 1,
      key: '"common"',
      value: [
        {
          key: 'code',
          value: '"00000"',
        },
        {
          key: 'message',
          value: '"성공"',
        },
        {
          key: 'time',
          value: '"2022-10-30 12:22:30.123"',
        },
      ],
    },
    {
      id: 2,
      key: '"result"',
      value: [
        {
          key: '',
          value: '',
        },
      ],
    },
  ],
};

const RefundCancleExplainContent = {
  explain: (
    <Content>
      환급 승인이 된 품목들에 대해 환급 취소를 진행합니다. 환급 취소는 개별
      품목에 대한 부분 취소는 불가능하며, 전체에 대한 환급 취소를 진행 후 취소
      품목을 제외한 물품들에 대해 다시 환급 요청을 진행해야 합니다. 이때 &nbsp;
      <Bold>refundNumber</Bold> 는 변경되며 물품확인번호는 다시 매핑해야 합니다.
      부분 취소에 대한 프로세스는 고객사의 시스템에 따라 달라지므로, 이에 따른
      내용은 본사로 문의해주시기 바랍니다. 요청 은 취소 유형 ({' '}
      <Bold>cancelType</Bold> )에 따라 <Bold>serialNubmer</Bold> 가 달라지며,
      현재는 REFUND, PAYMNET 두 개를 지원합니다.
    </Content>
  ),
  refund: (
    <Content>
      - 환급 승인은 되었으나 완료 확인 전에 발생한 건에 대한 취소 요청 시 사용.
      <br />- 즉, 최종 결제 전에 카드 또는 결제방식 등의 문제로 인해 결제가
      완료되지 않은 경우 사용됩니다.
      <br />- 이때 <Bold>serialNubmer</Bold> 에는 환급 요청의 결과로 받은
      <Bold>refundNumber</Bold>를 넣으면 됩니다.
    </Content>
  ),
  payment: (
    <Content>
      - 환급 확인까지 종료된 건에 대한 취소 요청 시 사용.
      <br />- 즉, 고객이 구매를 완료하고 변심등의 문제로 인해 주문 자체를 취소할
      때 사용됩니다.
      <br />- 이때 <Bold>serialNubmer</Bold> 가맹점 별 구매관리를 위한 일련번호,
      즉 환급 확인에 전달해주었던
      <Bold>purchaseNumber</Bold> 를 넣으면 됩니다.
    </Content>
  ),
};

const RefundCancleRequestContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Respone',
        content: {
          name: 'Authorization',
          type: 'String',
          required: 'O',
          description: 'Bearer <access_token>',
        },
      },
      {
        id: 2,
        name: 'Body',
        content: {
          name: 'cancelType',
          type: 'String',
          required: 'O',
          description: '환급 취소 유형(REFUND, PAYMENT)',
        },
      },
    ],
  },
  example: `curl -v -X POST "https://openapi.ktaxpay.com/v1/refund/cancel"  \\ \n-H "Bearer <access_token>"\n-d @<(cat <<EOF\n{\n  "cancelType": "REFUND or PAYMENT",\n  "purchaseNumber": "환급 승인 번호 또는 가맹점 별 구매관리를 위한 일련번호",\n}\nEOF\n)`,
};

const RefundCancleReponseContent = {
  graph: {
    head: [
      { id: 1, head: 'HTTP' },
      { id: 2, head: '항목' },
      { id: 3, head: '타입' },
      { id: 4, head: '필수' },
      { id: 5, head: '설명' },
    ],
    content: [
      {
        id: 1,
        name: 'Common body',
        content: {
          name: 'common',
          type: 'array(JSON)',
          required: 'O',
          description: '성공 실패 여부',
        },
      },
    ],
  },
  code: [
    {
      id: 1,
      key: '"common"',
      value: [
        {
          key: '"code"',
          value: '"00000"',
        },
        {
          key: '"message"',
          value: '"성공"',
        },
        {
          key: '"time"',
          value: '"2022-10-30 12:22:30.123"',
        },
      ],
    },

    {
      id: 2,
      key: '"result"',
      value: [
        {
          key: '',
          value: '',
        },
      ],
    },
  ],
};

export {
  IndexDevelopListContent,
  DevelopGraphContent,
  ServiceFlowContent,
  CallingContent,
  ApiDataContent,
  ResultDataContent,
  tokenExplainContent,
  TokenRequestContent,
  TokenResponseContent,
  ErrorGraphContent,
  RefundInquiryBasicContent,
  RefundInquiryExplainContent,
  RefundInquiryRequestContent,
  RefundInquiryReponseContent,
  RefundApplyExplainContent,
  RefundApplyRequestContent,
  RefundApplyReponseContent,
  RefundCancleExplainContent,
  RefundCancleRequestContent,
  RefundCancleReponseContent,
};
