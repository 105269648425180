import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SubmitCompleteConent } from '../../constants/inquiry';
import { isMobile } from '../../util/isMobile';

function SubmitComplete() {
  const { content, mobileContent, mobileSubContent, btnContent } =
    SubmitCompleteConent;
  return (
    <Container>
      {isMobile() ? (
        <>
          <Content>{mobileContent}</Content>
          <Content light>{mobileSubContent}</Content>
        </>
      ) : (
        <Content>{content}</Content>
      )}

      <Link to='/'>
        <Button>{btnContent}</Button>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 13px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 50px;
    height: 50px;
    margin-bottom: 32px;
  }
`;

const Content = styled.p<{ light?: boolean }>`
  white-space: pre-line;
  text-align: center;
  margin: 60px 0 80px 0;
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  @media ${(props) => props.theme.mobile} {
    margin: 0;
    font-size: ${(props) =>
      props.light
        ? props.theme.mobileFontSize.regular
        : props.theme.mobileFontSize.large};
    line-height: ${(props) =>
      props.light
        ? props.theme.mobileLineHeight.regular
        : props.theme.mobileLineHeight.large};
    font-weight: ${(props) => props.light && props.theme.fontWight.medium};
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.tintColor.white};
  background: ${(props) => props.theme.tintColor.primary};
  @media ${(props) => props.theme.mobile} {
    margin-top: 32px;
  }
`;

export default SubmitComplete;
