import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RendingProcessContent } from '../../constants/rendingExplain';
import { next, nextActive, pre, preActive } from '../../util/getImages';
import { isMobile } from '../../util/isMobile';

function RendingProcess() {
  const [step, setStep] = useState(0);
  const processRef = useRef<HTMLDivElement[]>([]);
  const processesRef = useRef<HTMLDivElement | null>(null);

  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    processRef.current.forEach((item, i) => {
      setTimeout(() => {
        item.style.transform = 'translateY(0)';
        item.style.animationPlayState = 'running';
      }, 300 * i - 1);
    });
    observer.disconnect();
  });

  useEffect(() => {
    observer.observe(processRef.current[0]);
  }, []);

  const movePre = () => {
    if (step === 0) {
      return;
    }
    setStep((prev) => prev - 1);
    processesRef.current!.style.transform = `translateX(calc(-300px * ${
      step - 1
    }))`;
  };

  const moveNext = () => {
    if (step === 2) {
      return;
    }
    setStep((prev) => prev + 1);
    processesRef.current!.style.transform = `translateX(calc(-300px * ${
      step + 1
    }))`;
  };

  return (
    <Wrapper>
      <Container>
        <Title>KTP 이용 단계</Title>
        <ProcessWrapper>
          <Arrow
            isVisible={isMobile()}
            src={step === 0 ? pre : preActive}
            style={{ left: 0 }}
            onClick={movePre}
          />
          <ProcessContainer>
            <Processes ref={processesRef}>
              {RendingProcessContent.map((item, i) => (
                <Process
                  key={item.id}
                  ref={(el) => (processRef.current[i] = el as HTMLDivElement)}
                >
                  <Content>
                    <ProcessTitle>{item.title}</ProcessTitle>
                    <ProcessContent>
                      {isMobile() ? item.mobileContent : item.content}
                    </ProcessContent>
                  </Content>
                  <Order>{item.order}</Order>
                </Process>
              ))}
            </Processes>
          </ProcessContainer>
          <Arrow
            isVisible={isMobile()}
            src={step === 2 ? next : nextActive}
            onClick={moveNext}
            style={{ right: '10%' }}
          />
        </ProcessWrapper>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.blueColor.scale1};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 57px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 110px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-bottom: 47px;
  }
`;

const ProcessWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;
`;

const ProcessContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    width: 300px;
    height: 210px;
    overflow: hidden;
    padding: 30px 0px;
    transition: all 300ms ease;
  }
`;

const Arrow = styled.img<{ isVisible: boolean }>`
  display: ${(props) => !props.isVisible && 'none'};
  top: 50%;
  width: 25px;
  height: 35px;
  object-fit: contain;
  z-index: 10;
`;

const Processes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 90px 50px;
  @media ${(props) => props.theme.mobile} {
    width: 900px;
    flex-wrap: nowrap;
    align-items: center;
    gap: 50px;
  }
`;

const Process = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% / 3);
  height: 242px;
  flex-basis: calc(100% / 3 - 50px);
  position: relative;
  border-radius: 40px;
  box-shadow: 0px 12px 28px rgba(218, 222, 228, 0.8);
  background: ${(props) => props.theme.tintColor.white};
  transform: translateY(40px);
  transition: transform 0.5s ease-in-out;
  animation: fadeIn 0.5s;
  animation-play-state: paused;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media ${(props) => props.theme.mobile} {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 12px 10px rgba(218, 222, 228, 0.8);
    transform: translateY(0px);
    animation: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
  position: relative;
  @media ${(props) => props.theme.mobile} {
    margin-left: 0px;
    justify-content: center;
    width: 100%;
    height: 160px;
  }
`;

const ProcessTitle = styled.h3`
  margin-bottom: 24px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.regular};
    line-height: ${(props) => props.theme.mobileLineHeight.regular};
    font-weight: 700;
  }
`;

const ProcessContent = styled.p`
  white-space: pre-line;
  text-align: left;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
`;

const Order = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -30px;
  right: 28px;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: ${(props) => props.theme.tintColor.primary};
  color: ${(props) => props.theme.tintColor.white};
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.xlarge};
    width: 60px;
    height: 60px;
    top: -20px;
    right: 10px;
    z-index: 10;
  }
`;

export default RendingProcess;
