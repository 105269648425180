import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { buttonApply } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ButtonApply({ setCurrActive }: IProps) {
  const applyRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(applyRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='apply'
        ref={(el) => (applyRef.current = el as HTMLHeadingElement)}
      >
        적용 예시
      </Title>
      <ImageContainer>
        <ButtonApplyImg src={buttonApply} />
      </ImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 120px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 130px;
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
  }
`;

const ButtonApplyImg = styled.img`
  width: 860px;
  height: 560px;
  object-fit: contain;
  @media ${(props) => props.theme.mobile} {
    width: 350px;
    height: 627px;
    object-fit: cover;
  }
`;

export default ButtonApply;
