import React, { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export interface GlobalLayoutProps {
  children: ReactNode;
}

function HelmetComponet({ children }: GlobalLayoutProps) {
  return (
    <HelmetProvider>
      <Helmet>
        <link
          rel='stylesheet'
          type='text/css'
          href='https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css'
        />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}

export default HelmetComponet;
