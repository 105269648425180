import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const theme: DefaultTheme = {
  fontFamily: 'Pretendard',
  fontWight: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
  fontSize: {
    bold: '44px',
    semibold: '32px',
    medium: '24px',
    noraml: '20px',
    regular: '16px',
    light: '14px',
  },
  mobileFontSize: {
    xlarge: '30px',
    large: '24px',
    medium: '20px',
    regular: '18px',
    small: '14px',
    xsmall: '12px',
  },
  lineHeight: {
    bold: '56px',
    semiBold: '48px',
    medium: '36px',
    normal: '28px',
    regular: '24px',
    light: '20px',
  },
  mobileLineHeight: {
    xlarge: '38px',
    large: '32px',
    medium: '28px',
    regular: '24px',
    small: '20px',
    xsmall: '16px',
  },
  tintColor: {
    primary: '#246CF6',
    gradient: 'linear-gradient(110deg, #5089F8 14.92%, #A7C4FB 99.92%)',
    mobileGradient: 'linear-gradient(0.17, #3DA6B0 50.22%, #067099 95.32%)',
    primaryVarient: '#E2F3FF',
    erorr: '#E42939',
    white: '#FFFFFF',
    codeBg: '#082C3A',
    codeGreen: '#73DD6A',
    codePuple: '#FC67FF',
  },
  grayScaleColor: {
    grayScale1: '#212121',
    grayScale2: '#757575',
    grayScale3: '#BDBDBD',
    grayScale4: '#DADEE4',
    grayScale5: '#F7F9FC',
  },
  blueColor: {
    scale1: '#F4F8FF',
    scale2: '#7CA7FA',
    scale3: '#5089F8',
    scale4: '#246CF6',
    scale5: '#1856CD',
  },
  Shadow: 'drop-shadow(0px 8px 16px rgba(0, 101, 140, 0.3))',
  mobile: '(max-width: 1024px)',
  desktop: '(min-width: 1023px)',
};

export const GlobalStyle = createGlobalStyle`
    ${reset};
    * {
        box-sizing: border-box;
        font-family: ${(props) => props.theme.fontFamily};
    }
    html, body {
      height: 100%
    }
    body {
      background-color: white;
      font-size: ${(props) => props.theme.fontSize.regular};;;
      font-family: ${(props) => props.theme.fontFamily};
      font-weight: ${(props) => props.theme.fontWight.regular};
      line-height: ${(props) => props.theme.lineHeight.regular};
    }
    a {
      color: ${(props) => props.theme.tintColor.white};
      text-decoration: none;
    }
    button {

      border: none;
      cursor: pointer;
    }
    input:-webkit-autofill {
       -webkit-box-shadow: 0 0 0 1000px white inset;
  }
`;
