import axios from 'axios';
import { axiosRequestConfiguration } from './config';

export const axiosInstance = axios.create(axiosRequestConfiguration);

const post = async <ReturnType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  queryParams?: ParamType
) =>
  axiosInstance.post<ReturnType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const api = {
  post,
};

export default api;
