import styled from 'styled-components';

import {
  ButtonBasicContent,
  LogoGraphContent,
} from '../../../constants/designGudieContent';
import DesignGraph from '../DesignGraph';

function ButtonBasic() {
  const { title, img, contents } = ButtonBasicContent;
  const { basic } = LogoGraphContent;

  return (
    <Container>
      <Title>{title}</Title>
      <ImageContainer>
        <ButtonBasicImg src={img} />
      </ImageContainer>
      <Contents>
        {contents.map((item) => (
          <Content key={item.id}>
            <ContentTitle>{item.title}</ContentTitle>
            <ContentContent>{item.content}</ContentContent>
          </Content>
        ))}
      </Contents>
      <DesignGraph content={basic} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 82px;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
  }
`;

const ButtonBasicImg = styled.img`
  width: 860px;
  height: 376px;
  object-fit: cover;
  @media ${(props) => props.theme.mobile} {
    width: 770px;
  }
`;

const Contents = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 17px;
  @media ${(props) => props.theme.mobile} {
  }
`;

const Content = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ContentContent = styled.p`
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    white-space: normal;
  }
`;

export default ButtonBasic;
