import React, { useState } from 'react';
import Footer from '../components/Common/Footer';
import Home from '../components/layout/Home';
import MenuModal from '../components/layout/MenuModal';
import NavigationBar from '../components/layout/NavigationBar';
import TaxFreeBanner from '../components/TaxFree/TaxFreeBanner';
import TaxFreeBottomBanner from '../components/TaxFree/TaxFreeBottomBanner';
import TaxFreeProduct from '../components/TaxFree/TaxFreeProduct';

function TaxFreePage() {
  const [isMenuModal, setIsMenuModal] = useState(false);
  return (
    <>
      {!isMenuModal ? (
        <>
          <NavigationBar setIsMenuModal={setIsMenuModal} />
          <Home />
          <TaxFreeBanner />
          <TaxFreeProduct />
          <TaxFreeBottomBanner />
          <Footer />
        </>
      ) : (
        <MenuModal setIsMenuModal={setIsMenuModal} />
      )}
    </>
  );
}

export default TaxFreePage;
