import { routes } from '../router/routes';

export const NavigationPage = [
  { id: 2, title: '서비스 소개', link: routes.service, active: false },
];

export const MenuModalPage = [
  { id: 2, title: '서비스 소개', link: routes.service },
  { id: 5, title: '도입 문의', link: routes.inquiry },
];

export const DropMenuList = [
  {
    id: 1,
    title: '디자인 가이드',
    link: '/design-guide',
    subMenu: [
      {
        id: 1,
        title: '로고 가이드',
        link: '/design-guide',
        list: [
          { id: 1, title: '1. 로고', location: 'logo' },
          { id: 2, title: '2. 활용 가이드라인', location: 'useLogo' },
        ],
      },
      {
        id: 2,
        title: '버튼 가이드',
        link: '/design-guide/button',
        list: [
          { id: 1, title: '1. 기본 버튼', location: 'basicButton' },
          { id: 2, title: '2. 비활성화 버튼', location: 'disableButton' },
          { id: 3, title: '3. 크기 변경', location: 'sizeChange' },
          { id: 4, title: '4. 적용 예시', location: 'apply' },
          { id: 5, title: '5. 활용 가이드 라인', location: 'useButton' },
        ],
      },
    ],
  },
  {
    id: 2,
    title: '개발자 가이드',
    link: '/develope-guide',
    subMenu: [
      {
        id: 1,
        title: '개요',
        link: '/develope-guide',
        list: [
          { id: 1, title: '1. 서비스 이용 신청 절차', location: 'summary' },
          { id: 2, title: '2. 키 발급 받기', location: 'keyAccept' },
          { id: 3, title: '3. 서비스 흐름(workflow)', location: 'serviceFlow' },
          { id: 4, title: '4. 호출', location: 'calling' },
          { id: 5, title: '5. API Data 결과', location: 'apiData' },
          {
            id: 6,
            title: '6. Result Data',
            location: 'resultData',
            link: '/develope-guide',
            list: [
              { id: 1, title: 'common', location: 'resultData' },
              { id: 2, title: 'result', location: 'resultData' },
            ],
          },
        ],
      },
      {
        id: 2,
        title: '토큰발급',
        link: '/develope-guide/token',
        list: [
          { id: 1, title: '1. 설명', location: 'tokenExplain' },
          { id: 2, title: '2. End point', location: 'tokenEndPoint' },
          { id: 3, title: '3. Request', location: 'tokenRequest' },
          { id: 4, title: '4. Response', location: 'tokenResponse' },
        ],
      },
      {
        id: 3,
        title: '에러코드',
        link: '/develope-guide/error',
        list: [
          { id: 1, title: '1. 기본 요청 오류', location: 'errorBasic' },
          {
            id: 2,
            title: '2. Authentication, Authorization',
            location: 'errorAuth',
          },
          { id: 3, title: '3. Refund', location: 'errorRefund' },
        ],
      },
      {
        id: 4,
        title: '환급',
        link: '/develope-guide/refund',
        list: [
          {
            id: 1,
            title: '환급가능 및 한도조회',
            location: 'refundInquiry',
            link: '/develope-guide/refund',
            list: [
              {
                id: 1,
                title: '1. 환급가능 및 한도조회',
                location: 'refundInquiryBasic',
              },
              {
                id: 2,
                title: '2. End point',
                location: 'refundInquiryEndPoint',
              },
              {
                id: 3,
                title: '3. Request',
                location: 'refundInquiryRequest',
              },
              {
                id: 4,
                title: '4. Respone',
                location: 'refundInquiryResponse',
              },
            ],
          },
          {
            id: 2,
            title: '환급신청',
            location: 'refundApply',
            link: '/develope-guide/refund/apply',
            list: [
              {
                id: 1,
                title: '1. 환급 승인 및 완료 절차',
                location: 'refundProcessSuccess',
              },
              {
                id: 2,
                title: '2. 환급 요청 및 승인 절차',
                location: 'refundApplyProcess',
              },
              {
                id: 3,
                title: '3. EndPoint',
                location: 'refundApplyEndPoint',
              },
              {
                id: 4,
                title: '4. Request',
                location: 'refundApplyRequest',
              },
              {
                id: 5,
                title: '5. Response',
                location: 'refundApplyResponse',
              },
            ],
          },
          {
            id: 3,
            title: '환급취소',
            location: 'refundCancleProcess',
            link: '/develope-guide/refund/cancle',
            list: [
              {
                id: 1,
                title: '1. 환급 취소 절차',
                location: 'refundCancleProcess',
              },
              {
                id: 2,
                title: '2. EndPoint',
                location: 'refundCancleEndPoint',
              },
              {
                id: 3,
                title: '3. Request',
                location: 'refundCancleRequest',
              },
              {
                id: 4,
                title: '4. Response',
                location: 'refundCancleResponse',
              },
            ],
          },
        ],
      },
    ],
  },
];
