interface IProps {
  setIsActive: (value: string) => void;
}

export const getIntersectionObserver = (
  setIsActive: any,
  percent: number = 0.7
) => {
  let direction = '';
  let prevYposition = 0;

  const checkScrollDirection = (prevY: number) => {
    if (window.scrollY === 0 && prevY === 0) {
      return;
    } else if (window.scrollY > prevY) {
      direction = 'down';
    } else {
      direction = 'up';
    }

    prevYposition = window.scrollY;
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        checkScrollDirection(prevYposition);

        if (
          (direction === 'down' && !entry.isIntersecting) ||
          (direction === 'up' && entry.isIntersecting)
        ) {
          setIsActive(entry.target.id);
        }
      });
    },
    {
      rootMargin: '-158px 0px 0px 0px',
    }
  );

  return observer;
};
