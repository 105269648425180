import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { serviceProcessContent } from '../../constants/serviceContent';
import { isMobile } from '../../util/isMobile';

interface IProcess {
  id: number;
  content: string;
  background: string;
  isArrow: boolean;
}

function ServiceProcess() {
  const processRef = useRef<HTMLDivElement[]>([]);
  let processes: IProcess[] = [];
  const { title, process, mobileProcess, img } = serviceProcessContent;

  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    processRef.current.forEach((item, i) => {
      setTimeout(() => {
        item.style.transform = 'translateY(0)';
        item.style.animationPlayState = 'running';
      }, 300 * i - 1);
    });
    observer.disconnect();
  });

  useEffect(() => {
    processes = isMobile() ? mobileProcess : process;
  }, []);

  useEffect(() => {
    observer.observe(processRef.current[0]);
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <ProcessContainer>
          {(isMobile() ? mobileProcess : process).map((item, i) => (
            <Process
              key={item.id}
              index={i}
              ref={(el) => (processRef.current[i] = el as HTMLDivElement)}
            >
              <ProcessContent background={item.background}>
                {item.content}
              </ProcessContent>
              {item.isArrow && <Image src={img} />}
            </Process>
          ))}
        </ProcessContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.blueColor.scale1};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 48px 0 78px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const Title = styled.h1`
  white-space: pre-line;
  margin-bottom: 80px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin-bottom: 48px;
  }
`;

const ProcessContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media ${(props) => props.theme.mobile} {
    position: relative;
    padding-left: 34px;
    gap: 0px;
  }
`;

const Process = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.mobile} {
    position: relative;
    right: ${(props) => props.index * 17}px;
    transform: translateY(10px);
    transition: transform 0.5s ease-in-out;
    animation: fadeIn 0.5s;
    animation-play-state: paused;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

const ProcessContent = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-right: 20px;
  white-space: pre-wrap;
  color: ${(props) => props.theme.tintColor.white};
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  background: ${(props) => props.background};
  @media ${(props) => props.theme.mobile} {
    width: 120px;
    height: 120px;
    margin-right: 0px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const Image = styled.img`
  width: 44px;
  height: 44px;
  object-fit: contain;
`;

export default ServiceProcess;
