import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from './style';
import HelmetComponet from './components/Helmet/HelmetComponet';
import RouterContainer from './router';
import ChannelService from './components/ChannelService';

function App() {
  const channelService = new ChannelService();

  useEffect(() => {
    channelService.boot();
    return () => {
      channelService.shutdown();
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <HelmetComponet>
        <GlobalStyle />
        <RouterContainer />
      </HelmetComponet>
    </ThemeProvider>
  );
}

export default App;
