import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RefundApplyExplainContent } from '../../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function EndPoint({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container
      id='refundApplyEndPoint'
      ref={(el) => (targetRef.current = el as HTMLDivElement)}
    >
      <SubTitle>설명</SubTitle>
      <Content>{RefundApplyExplainContent.explain}</Content>
      <SubTitle>End point</SubTitle>
      <Content>{RefundApplyExplainContent.endpoint}</Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin-top: 158px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
    scroll-margin-top: 158px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* white-space: pre-line; */
`;

const SubTitle = styled.h3`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const Bold = styled.span<{ padding?: string }>`
  white-space: pre-line;
  padding: ${(props) => (props.padding ? props.padding : '0px 14px')};
  border-radius: 8px;
  text-align: center;
  background: ${(props) => props.theme.tintColor.primaryVarient};
  color: ${(props) => props.theme.tintColor.primary};
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default EndPoint;
