import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { downArrow, upArrow } from '../../util/getImages';
import { ISubMenu } from './MobileDropDown';
import MobileSubDropItem from './MobileSubDropItem';

interface IProps {
  menuItem: ISubMenu;
  currActive: string;
  setIsParentDrop: (isDrop: boolean) => void;
}

function MobileSubDropMenu({ menuItem, setIsParentDrop, currActive }: IProps) {
  const [isDrop, setIsDrop] = useState(false);
  const [isCurr, setIsCurr] = useState(false);

  const { pathname: currentLink } = useLocation();

  useEffect(() => {
    if (currentLink === menuItem.link) {
      setIsDrop(true);
      setIsCurr(true);
    } else {
      setIsCurr(false);
    }

    menuItem.list &&
      menuItem.list.forEach((item) => {
        if (item.link === currentLink) {
          setIsDrop(true);
        }
      });
  }, []);

  return (
    <>
      <ItemContainer>
        <Link to={menuItem.link} style={{ width: '100%' }}>
          <TitleContainer>
            <Title isCurr={isCurr}>{menuItem.title}</Title>
          </TitleContainer>
        </Link>
        <Image
          src={isDrop ? upArrow : downArrow}
          onClick={() => setIsDrop((prev) => !prev)}
        />
      </ItemContainer>
      {isDrop && (
        <SubList>
          {menuItem.list.map((subItem) => (
            <MobileSubDropItem
              key={subItem.id}
              menuItem={menuItem}
              subItem={subItem}
              currActive={currActive}
              setIsParentDrop={setIsParentDrop}
            />
          ))}
        </SubList>
      )}
    </>
  );
}

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 40px;
  background: ${(props) => props.theme.tintColor.white};
`;

const TitleContainer = styled.div`
  width: 100%;
`;

const Title = styled.span<{ isCurr: boolean }>`
  color: ${(props) =>
    props.isCurr
      ? props.theme.grayScaleColor.grayScale1
      : props.theme.grayScaleColor.grayScale2};
  font-size: ${(props) => props.theme.mobileFontSize.regular};
  line-height: ${(props) => props.theme.mobileLineHeight.regular};
  font-weight: ${(props) => props.theme.fontWight.bold};
`;

const Image = styled.img<{ isDown?: boolean }>`
  position: ${(props) => props.isDown && 'absolute'};
  right: 20px;
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const SubList = styled.ul<{ isDown?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0;
  padding-top: ${(props) => props.isDown && '10px'};
  background: ${(props) => props.theme.blueColor.scale1};
`;

export default MobileSubDropMenu;
