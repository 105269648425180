import { useState } from 'react';
import Footer from '../components/Common/Footer';
import MenuModal from '../components/layout/MenuModal';
import NavigationBar from '../components/layout/NavigationBar';
import RendingBottomBanner from '../components/Rending/RendingBottomBanner';
import RendingCooperation from '../components/Rending/RendingCooperation';
import RendingHome from '../components/Rending/RendingHome';
import RendingIntroduce from '../components/Rending/RendingIntroduce';
import RendingMainFunction from '../components/Rending/RendingMainFunction';
import RendingProcess from '../components/Rending/RendingProcess';

function Rending() {
  const [isMenuModal, setIsMenuModal] = useState(false);
  return (
    <>
      {!isMenuModal ? (
        <>
          <NavigationBar setIsMenuModal={setIsMenuModal} />
          <RendingHome />
          <RendingIntroduce />
          <RendingCooperation />
          <RendingMainFunction />
          <RendingProcess />
          <RendingBottomBanner />
          <Footer />
        </>
      ) : (
        <MenuModal setIsMenuModal={setIsMenuModal} />
      )}
    </>
  );
}

export default Rending;
