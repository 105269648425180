import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { DropMenuList } from '../../constants/navigation';
import { downArrow } from '../../util/getImages';
import MobileDropDown from '../Common/MobileDropDown';

interface IProps {
  currActive: string;
}

function MobileSideMenu({ currActive }: IProps) {
  const [isDrop, setIsDrop] = useState(true);
  const [dropMenu, setDropMenu] = useState(DropMenuList);
  const { pathname: currLink } = useLocation();

  useEffect(() => {
    setIsDrop(false);
  }, []);

  useLayoutEffect(() => {
    if (currLink.includes('/develope-guide')) {
      let newArray = [];
      newArray.push(dropMenu[1]);
      newArray.push(dropMenu[0]);
      setDropMenu(newArray);
    }
  }, []);

  return (
    <Container>
      <Header isDrop={isDrop} onClick={() => setIsDrop((prev) => !prev)}>
        <Title>
          {currLink.includes('/design-guide')
            ? '디자인 가이드'
            : '개발자 가이드'}
        </Title>
        <Image src={downArrow} />
      </Header>
      <SideMenuList isDrop={isDrop}>
        {dropMenu.map((item) => (
          <MobileDropDown
            key={item.id}
            menuList={item}
            currActive={currActive}
            setIsParentDrop={setIsDrop}
          />
        ))}
      </SideMenuList>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 73px;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  background: ${(props) => props.theme.tintColor.white};
  overflow: scroll;
  max-height: 348px;
`;

const Header = styled.div<{ isDrop?: boolean }>`
  display: ${(props) => (props.isDrop ? 'none' : 'flex')};
  justify-content: space-between;
  padding: 20px;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.mobileFontSize.medium};
  line-height: ${(props) => props.theme.mobileLineHeight.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const SideMenuList = styled.div<{ isDrop: boolean }>`
  display: ${(props) => (props.isDrop ? 'block' : 'none')};
`;

export default MobileSideMenu;
