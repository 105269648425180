import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IndexDesignListContent } from '../../../constants/designGudieContent';
import { isMobile } from '../../../util/isMobile';
import MobileSideMenu from '../../layout/MobileSideMenu';
import SideNavigationIndex from '../../layout/SideNavigationIndex';
import LogoGuide from './LogoGuide';
import LogoUseGuide from './LogoUseGuide';

function DesignLogo() {
  const [currActive, setCurrActive] = useState('logo');
  const indexList = IndexDesignListContent.logo;

  const curr: any = useLocation();

  useEffect(() => {
    if (curr?.state) {
      const location = curr?.state;
      const element = document.querySelector(`#${location.location}`);
      element?.scrollIntoView({ block: 'center' });
    }
  }, [curr]);

  return (
    <Wrapper>
      <Container>
        <LogoGuide setCurrActive={setCurrActive} />
        <LogoUseGuide setCurrActive={setCurrActive} />
      </Container>
      {isMobile() ? (
        <MobileSideMenu currActive={currActive} />
      ) : (
        <SideNavigationIndex indexList={indexList} currActive={currActive} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  margin-left: 2%;
  flex: 1;
  @media ${(props) => props.theme.mobile} {
    margin-left: 20px;
    gap: 0px;
    padding-top: 115px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 80px 0px 56px 0px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  background: ${(props) => props.theme.tintColor.white};
  @media ${(props) => props.theme.mobile} {
    padding-top: 0;
    gap: 0px;
  }
`;

export default DesignLogo;
