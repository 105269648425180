import React from 'react';
import styled from 'styled-components';

interface IProps {
  content: IContet[];
}

interface IContet {
  id: number;
  key: string;
  value: IValue[];
}

interface IValue {
  key: string;
  value: any;
}

function DevelopCode({ content }: IProps) {
  return (
    <Container>
      <Text>{'{'}</Text>
      {content.map((item) => (
        <Content key={item.id}>
          <KeyText>{item.key}</KeyText>
          <Text isMargin>{':'}</Text>
          <Text isMargin>{'{'}</Text>
          {item.value.map((v) => (
            <Content key={v.key}>
              {v.key ? (
                <>
                  <KeyText>{v.key}</KeyText>
                  <Text isMargin>{':'}</Text>
                  {v.value.key ? <></> : <ValueText>{v.value}</ValueText>}
                </>
              ) : (
                '. . .'
              )}
            </Content>
          ))}
          <Text>{'} ,'}</Text>
        </Content>
      ))}
      <Text>{'}'}</Text>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 20px;
  background: ${(props) => props.theme.tintColor.codeBg};
  color: ${(props) => props.theme.grayScaleColor.grayScale4};
  font-size: ${(props) => props.theme.mobileFontSize.small};
  line-height: ${(props) => props.theme.mobileLineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Content = styled.div`
  padding-left: 30px;
  @media ${(props) => props.theme.mobile} {
    width: 860px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
  }
`;

const Text = styled.span<{ isMargin?: boolean }>`
  margin-left: ${(props) => props.isMargin && '15px'};
  color: ${(props) => props.theme.grayScaleColor.grayScale4};
`;

const KeyText = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.tintColor.codePuple};
`;

const ValueText = styled.span`
  margin-left: 20px;
  white-space: pre-wrap;
  color: ${(props) => props.theme.tintColor.codeGreen};
`;

export default DevelopCode;
