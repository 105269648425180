import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function EndPoint({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='tokenEndPoint'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        End point
      </Title>
      <Content>
        End point : <Bold>https://openapi.ktaxpay.com/v1/token</Bold>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 130px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const Content = styled.div``;

const Bold = styled.span`
  display: inline-block;
  width: 296px;
  height: 28px;
  text-align: center;
  border-radius: 8px;
  color: ${(props) => props.theme.tintColor.primary};
  background: ${(props) => props.theme.tintColor.primaryVarient};
  @media ${(props) => props.theme.mobile} {
    width: 268px;
    height: 24px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default EndPoint;
