import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { summaryProcess } from '../../../util/getImages';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ServiceProcess({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container
      id='summary'
      ref={(el) => (targetRef.current = el as HTMLDivElement)}
    >
      <Title>개요</Title>
      <SubTitle>서비스 이용 신청 절차</SubTitle>
      <Content>
        KTP는 OAuth 2.0 표준을 서비스 구조에 맞게 변경하여 인증을 진행합니다.
      </Content>
      <ImageContainer>
        <Image src={summaryProcess} />
      </ImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin-top: 158px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
    scroll-margin-top: 158px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const SubTitle = styled.h3`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const Content = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    padding-right: 16px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Image = styled.img`
  width: 860px;
  height: 988px;
  object-fit: contain;
`;

export default ServiceProcess;
