import React from 'react';
import styled from 'styled-components';
import { passport } from '../../util/getImages';
import {
  RendingFunction,
  RendingFunctionFirst,
} from '../../constants/rendingExplain';
import { isMobile } from '../../util/isMobile';

function MainFunctionContent() {
  return (
    <Container>
      <Passport>
        <ContentContainer>
          <Title>{RendingFunctionFirst.title}</Title>
          {isMobile() && <PassportImage src={passport} />}
          <Content>
            {isMobile()
              ? RendingFunctionFirst.mobileContent
              : RendingFunctionFirst.content}
          </Content>
        </ContentContainer>
        {!isMobile() && <PassportImage src={passport} />}
      </Passport>

      <FunctionContainer>
        {RendingFunction.map((item) => (
          <Function key={item.id}>
            <ContentContainer>
              <Title>{item.title}</Title>
              {isMobile() && <FunctionImage src={item.img} />}
              <Content>{item.content}</Content>
            </ContentContainer>
            {!isMobile() && <FunctionImage src={item.img} />}
          </Function>
        ))}
      </FunctionContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Passport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 83px;
  padding: 60px 40px;
  border: 1px solid #dadee4;
  border-radius: 12px;
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  margin-bottom: 40px;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 36px 28px;
    margin-bottom: 32px;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${(props) => props.theme.mobile} {
    align-items: center;
  }
`;

const Title = styled.h3`
  margin-bottom: 20px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const Content = styled.p`
  text-align: start;
  white-space: pre-line;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    margin-top: 20px;
  }
`;

const PassportImage = styled.img`
  width: 210px;
  height: 120px;
  object-fit: contain;
`;

const FunctionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`;

const Function = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  padding: 60px 40px;
  border: 1px solid #dadee4;
  border-radius: 12px;
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  @media ${(props) => props.theme.mobile} {
    padding: 36px 22px;
    width: 100%;
  }
`;

const FunctionImage = styled.img`
  width: 84px;
  height: 84px;
  object-fit: contain;
`;

export default MainFunctionContent;
