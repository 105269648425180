import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getIntersectionObserver } from '../../../../util/observer';
import refundProcessImg from '../../../../assets/guide/develop/refund_apply_process.png';

interface IProps {
  setCurrActive: (value: string) => void;
}

function RefundApplyProcess({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container
      id='refundApplyProcess'
      ref={(el) => (targetRef.current = el as HTMLDivElement)}
    >
      <Title>환급 요청</Title>
      <SubTitle>환급 요청 및 승인 절차</SubTitle>
      <ImageContainer>
        <Image src={refundProcessImg} width='860px' height='1056px' />
      </ImageContainer>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  scroll-margin-top: 158px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
    scroll-margin-top: 158px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const ImageContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Image = styled.img`
  object-fit: contain;
`;

const SubTitle = styled.h3`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;
export default RefundApplyProcess;
