import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { LogoUseGuideContent } from '../../../constants/designGudieContent';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function LogoUseGuide({ setCurrActive }: IProps) {
  const { title, contents } = LogoUseGuideContent;
  const logoUseRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(logoUseRef.current!);
  }, []);

  return (
    <Container
      id='useLogo'
      ref={(el) => (logoUseRef.current = el as HTMLDivElement)}
    >
      <Title>{title}</Title>
      <ContentContainer>
        {contents.map((item) => (
          <Content key={item.id}>
            <ContentImg src={item.img} />
            <ContentDont>{item.dont}</ContentDont>
            <ContentCaution>{item.content}</ContentCaution>
          </Content>
        ))}
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 82px;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 32px;
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
    padding-bottom: 56px;
  }
`;

const Content = styled.div``;

const ContentImg = styled.img`
  width: 410px;
  height: 402px;
  object-fit: cover;
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 264px;
  }
`;

const ContentDont = styled.p`
  margin: 24px 0 8px 0;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  color: ${(props) => props.theme.tintColor.erorr};
  ::before {
    display: block;
    content: '';
    height: 8px;
    width: 100%;
    transform: translateY(-29px);
    background: ${(props) => props.theme.tintColor.erorr};
  }
  @media ${(props) => props.theme.mobile} {
    margin: 20px 0 1.5px 0;
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ContentCaution = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default LogoUseGuide;
