import {
  arrow,
  detailEnglish,
  detailKorea,
  english,
  good,
  korea,
  payment,
  security,
  setting,
} from '../util/getImages';

const serviceBannerContent = {
  title: 'KTP(Korea Tax Free Payments)',
  mobileTitle: 'KTP\n(Korea Tax Free Payments)',
  content1:
    '외국인 관광객이 국내에서 물품을 구매할 시,\n 외국인 관광객의 여권을 인증하여 부가가치세의 일부를\n 환급받도록 하는 ‘즉시 환급형 TAX FREE’ 서비스입니다.',
  content2:
    '‘즉시 환급’은 외국인 관광객이 물품을 구매할 시,\n 물품에 포함된 부가가치세를 즉시 차감하여 결제하는 방식으로,\n 타 TAX FREE 방식에 비해 빠르고 편리합니다.',
  img: payment,
};

const serviceProcessContent = {
  title: 'KTP 환급 절차',
  process: [
    { id: 1, content: '물품 구매', background: '#7CA7FA', isArrow: true },
    { id: 2, content: '여권 인증', background: '#5089F8', isArrow: true },
    { id: 3, content: 'TAX FREE 적용', background: '#246CF6', isArrow: true },
    { id: 4, content: '물품 결제', background: '#1856CD', isArrow: false },
  ],
  mobileProcess: [
    { id: 1, content: '01\n물품 구매', background: '#7CA7FA', isArrow: false },
    {
      id: 2,
      content: '02\n텍스프리\n적용',
      background: '#246CF6',
      isArrow: false,
    },
    { id: 3, content: '03\n물품 결제', background: '#1856CD', isArrow: false },
  ],
  img: arrow,
};

const serviceDetailContent = {
  title: 'KTP API 상세 내용',
  content:
    '외국인 관광객 고객의 여권을 인증한 후 TAX FREE를 요청합니다.\n 결제 단계에서 TAX FREE 버튼을 누르면, 여권을 촬영하여 외국인 관광객임을 인증한 후 환급 승인을 요청합니다.',
  mobileContent:
    '외국인 관광객 고객의 여권을 인증한 후\nTAX FREE를 요청합니다.\n\n결제 단계에서 TAX FREE 버튼을 누르면, 여권을 촬영하여\n외국인 관광객임을 인증한 후 환급 승인을 요청합니다.',
  processTitle: 'KTP TAX FREE를 적용한 경우',
  process: [
    'KTP TAX FREE 버튼 클릭',
    '여권 스캔',
    '여권 정보 확인',
    'TAX FREE 적용',
  ],
  korea: {
    world: korea,
    img: detailKorea,
  },
  english: {
    world: english,
    img: detailEnglish,
  },
};

const serviceFeatureContent = {
  title: 'KTP 서비스 주요 특징',
  contents: [
    {
      id: 1,
      title: '간편하고 빠른 즉시 환급',
      content:
        '타 TAX FREE 방식과 비교했을 때\n 외국인 관광객 고객이 TAX FREE를 받기 위해\n  처리해야 하는 절차가 매우 간단합니다.',
      img: good,
    },
    {
      id: 2,
      title: '보안성이 높은 서비스',
      content:
        '외국인 관광객의 여권을 인증하는 과정에서\n 여권 정보는 저장되지 않아 개인정보 유출위험이\n  없고, 환급 내역을 안전하게 저장합니다.',
      img: security,
    },
    {
      id: 3,
      title: '다양한 서비스 환경에서 적용',
      content:
        '전문가가 고객사 서비스의 구조를 파악하여\n 쉽고 빠르게 API를 연동하고 개발할 수 있도록\n  다양한 기술지원을 제공합니다.',
      img: setting,
    },
  ],
};

export {
  serviceBannerContent,
  serviceProcessContent,
  serviceDetailContent,
  serviceFeatureContent,
};
