import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { serviceFeatureContent } from '../../constants/serviceContent';
import { isMobile } from '../../util/isMobile';

function ServiceFeature() {
  const { title, contents } = serviceFeatureContent;
  const contentRef = useRef<HTMLElement[]>([]);

  const observer = new IntersectionObserver((targets) => {
    if (isMobile()) {
      targets.forEach((item: any) => {
        if (item.isIntersecting) {
          item.target.style.animationPlayState = 'running';
          item.target.style.transform = 'translateY(0px)';
        }
      });
    } else {
      if (!targets[0].isIntersecting) {
        return;
      }
      contentRef.current.forEach((item, i) => {
        setTimeout(() => {
          item.style.animationPlayState = 'running';
          item.style.transform = 'translateY(0px)';
        }, 400 * i - 1);
      });
    }
  });

  useEffect(() => {
    observer.observe(contentRef.current[0]);
    if (isMobile()) {
      observer.observe(contentRef.current[1]);
      observer.observe(contentRef.current[2]);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <ContentContainer>
          {contents.map((item, i) => (
            <Content
              className='content'
              key={item.id}
              ref={(el) => (contentRef.current[i] = el as HTMLDivElement)}
            >
              <ContentImg src={item.img} />
              <ContentTitle>{item.title}</ContentTitle>
              <ContentContent>{item.content}</ContentContent>
            </Content>
          ))}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.blueColor.scale1};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 76px 10px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const Title = styled.h1`
  white-space: pre-line;
  margin-bottom: 80px;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-bottom: 25px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 56px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px;
  border-radius: 12px;
  background: ${(props) => props.theme.tintColor.white};
  filter: drop-shadow(0px 12px 28px rgba(218, 222, 228, 0.8));
  transform: translateY(30px);
  transition: transform 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
  animation-play-state: paused;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .add {
    animationplaystate: running;
    transform: 'translateY(0px)';
  }
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const ContentImg = styled.img`
  width: 78px;
  height: 78px;
`;

const ContentTitle = styled.h3`
  margin: 34px 0 44px 0;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin: 0;
  }
`;

const ContentContent = styled.p`
  white-space: pre-line;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default ServiceFeature;
