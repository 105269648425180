import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  content: IContent[];
  head: IHead[];
  isOneLine?: boolean;
}

interface IHead {
  id: number;
  head: string;
}

interface IContent {
  id: number;
  name?: string;
  content: ReactNode | IContentItem | string;
}

interface IContentItem {
  name: string;
  type: string;
  required: string;
  description: string;
}

function DevelopGraph({ content, head, isOneLine }: IProps) {
  return (
    <Container>
      <Table>
        <Thead>
          <Tr>
            {head.map((item: any) => (
              <Th key={item.id}>{item.head}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!isOneLine ? (
            content.map((item: any) => (
              <Tr key={item.id}>
                {item.name && <Td>{item.name}</Td>}
                {item.content.name ? (
                  <>
                    <Td>{item.content.name}</Td>
                    <Td>{item.content.type}</Td>
                    <Td>{item.content.required}</Td>
                    <Td>{item.content.description}</Td>
                  </>
                ) : (
                  <>
                    <Td>{item.content}</Td>
                  </>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              {content.map((item: any) => (
                <Td style={{ fontWeight: 400 }} key={item.id}>
                  {item.content}
                </Td>
              ))}
            </Tr>
          )}
        </Tbody>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;

const Table = styled.table`
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    width: 860px;
  }
`;

const Thead = styled.thead`
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  border-left: none;
  border-right: none;
`;

const Th = styled.th`
  padding: 12px 30px;
  text-align: left;
  :last-child {
    border-right: none;
  }
  border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const Td = styled.td`
  white-space: pre-line;
  padding: 12px 32px;
  text-align: left;
  vertical-align: middle;
  :last-child {
    border-right: none;
  }
  border-right: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default DevelopGraph;
