import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import buttonAi from '../../../assets/download/button_ai.ai';
import buttonZip from '../../../assets/download/button_zip.zip';
import { getIntersectionObserver } from '../../../util/observer';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ButtonDownload({ setCurrActive }: IProps) {
  const buttonBasicRef = useRef<HTMLDivElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(buttonBasicRef.current!);
  }, []);

  return (
    <LogContainer
      id='basicButton'
      ref={(el) => (buttonBasicRef.current = el as HTMLDivElement)}
    >
      <Title>KTP TAX FREE 버튼</Title>
      <DownLoads>
        <DownLoad href={buttonAi} download='로고(AI)'>
          <DownText>버튼 이미지 다운로드 (AI)</DownText>
        </DownLoad>
        <DownLoad href={buttonZip} download='로고(PNG)'>
          <DownText>버튼 이미지 다운로드 (PNG)</DownText>
        </DownLoad>
      </DownLoads>
    </LogContainer>
  );
}

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media ${(props) => props.theme.mobile} {
    scroll-margin-top: 150px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const DownLoads = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 7px;
  }
`;

const DownLoad = styled.a`
  padding: 10px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  background: ${(props) => props.theme.tintColor.primary};
  color: ${(props) => props.theme.tintColor.white};
  :hover {
    opacity: 0.8;
  }
  @media ${(props) => props.theme.mobile} {
    width: 183px;
    padding: 10px 0;
    text-align: center;
  }
`;

const DownText = styled.span`
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default ButtonDownload;
