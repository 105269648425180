import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TokenResponseContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import DevelopCode from '../DevelopCode';
import DevelopGraph from '../DevelopGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Response({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();
  const { graph, code } = TokenResponseContent;

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='tokenResponse'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        Response
      </Title>
      <Contents>
        <Content>
          MediaType : <Bold>application/json</Bold>
        </Content>
        <Content>
          <p style={{ marginBottom: '25px' }}>Specification</p>
          <DevelopGraph head={graph.head} content={graph.content} />
        </Content>
        <Content>
          <p style={{ marginBottom: '25px' }}>Example</p>
          <DevelopCode content={code} />
        </Content>
      </Contents>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 100px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const Content = styled.div`
  width: 100%;
`;

const Bold = styled.span<{ isShort?: boolean }>`
  display: inline-block;
  width: 138px;
  height: 28px;
  padding-top: 2px;
  text-align: center;
  border-radius: 8px;
  color: ${(props) => props.theme.tintColor.primary};
  background: ${(props) => props.theme.tintColor.primaryVarient};
  @media ${(props) => props.theme.mobile} {
    width: 128px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default Response;
