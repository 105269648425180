import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ButtonUseGuideContent } from '../../../constants/designGudieContent';
import { getIntersectionObserver } from '../../../util/observer';

export interface IProps {
  setCurrActive: (value: string) => void;
}

function ButtonUseGuide({ setCurrActive }: IProps) {
  const { title, contents } = ButtonUseGuideContent;
  const buttonUseRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);

  useEffect(() => {
    observer.observe(buttonUseRef.current!);
  }, []);

  return (
    <Container
      id='useButton'
      ref={(el) => (buttonUseRef.current = el as HTMLHeadingElement)}
    >
      <Title>{title}</Title>
      <ContentContainer>
        {contents.map((item) => (
          <Content key={item.id}>
            <ImageContainer>
              <UseGuideImg src={item.img} />
              <UseGuideDont>{item.dont}</UseGuideDont>
              <UseGuideCaution>{item.content}</UseGuideCaution>
            </ImageContainer>
          </Content>
        ))}
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: 82px;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    padding-top: 56px;
  }
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  @media ${(props) => props.theme.mobile} {
    flex-wrap: nowrap;
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
    gap: 0px;
    padding-bottom: 56px;
  }
`;

const Content = styled.div``;

const ImageContainer = styled.div``;

const UseGuideImg = styled.img`
  width: 410px;
  object-fit: contain;
`;

const UseGuideDont = styled.p`
  margin: 24px 0 8px 0;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  color: ${(props) => props.theme.tintColor.erorr};
  ::before {
    display: block;
    content: '';
    height: 8px;
    width: 100%;
    transform: translateY(-29px);
    background: ${(props) => props.theme.tintColor.erorr};
  }
`;

const UseGuideCaution = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

export default ButtonUseGuide;
