import { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from '../../util/isMobile';
import MenuModal from '../layout/MenuModal';
import NavigationBar from '../layout/NavigationBar';
import Submit from './Submit';
import SubmitComplete from './SubmitComplete';

function InquiryMain() {
  const [isComplete, setIsComplete] = useState(false);
  const [isMenuModal, setIsMenuModal] = useState(false);

  return (
    <>
      {isMobile() && (
        <NavigationBar isInquiry setIsMenuModal={setIsMenuModal} />
      )}
      {!isMenuModal ? (
        <Wrapper>
          <Container>
            {isComplete ? (
              <SubmitComplete />
            ) : (
              <Submit setIsComplete={setIsComplete} />
            )}
          </Container>
        </Wrapper>
      ) : (
        <MenuModal setIsMenuModal={setIsMenuModal} />
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 150px 0;
  background: ${(props) => props.theme.tintColor.white};
  @media ${(props) => props.theme.mobile} {
    padding: 120px 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default InquiryMain;
