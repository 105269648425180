import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { IList, ILowestList } from './MobileDropDown';
import { downArrow, upArrow } from '../../util/getImages';

interface IProps {
  subItem: IList;
  subItemList: ILowestList[];
  link: string;
  currActive: string;
  setIsParentDrop: (isDrop: boolean) => void;
}

function LowestDrop({
  subItem,
  subItemList,
  link,
  setIsParentDrop,
  currActive,
}: IProps) {
  const [isDrop, setIsDrop] = useState(false);

  const closeDrop = (e: any) => {
    setIsParentDrop(false);
  };
  const { pathname: currentLink } = useLocation();

  useEffect(() => {
    if (currentLink === subItem.link) {
      setIsDrop(true);
    }
  }, []);

  return (
    <Container>
      <SubItem
        currActive={
          currActive === subItem.location ||
          currActive.includes(subItem.location)
        }
      >
        <SubItemHeader>
          <Link to={subItem.link!} state={{ location: subItem.location }}>
            <SubText currActive={currActive === subItem.location}>
              {subItem.title}
            </SubText>
          </Link>
          <Image
            src={isDrop ? upArrow : downArrow}
            isDown
            onClick={() => setIsDrop((prev) => !prev)}
          />
        </SubItemHeader>
      </SubItem>

      {isDrop && (
        <SubList onClick={closeDrop}>
          {subItemList.map((item: any) => (
            <Link key={item.id} to={link} state={{ location: item.location }}>
              <SubItem>
                <SubText currActive={currActive === item.location}>
                  {item.title}
                </SubText>
              </SubItem>
            </Link>
          ))}
        </SubList>
      )}
    </Container>
  );
}

const Container = styled.div``;

const SubList = styled.div<{ currActive?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px 0px 20px;
  background: ${(props) => props.currActive && '#F2F3FF'};
`;

const SubItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubItem = styled.li<{ currActive?: boolean }>`
  padding: 10px 60px;
  font-size: 16px;
  line-height: ${(props) => props.theme.mobileLineHeight.small};
  background: ${(props) =>
    props.currActive && props.theme.tintColor.primaryVarient};
`;

const SubText = styled.div<{ currActive?: boolean }>`
  font-size: 16px;
  line-height: ${(props) => props.theme.mobileLineHeight.small};
  color: ${(props) =>
    props.currActive
      ? props.theme.tintColor.primary
      : props.theme.grayScaleColor.grayScale2};
`;

const Image = styled.img<{ isDown?: boolean }>`
  position: ${(props) => props.isDown && 'absolute'};
  right: 20px;
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export default LowestDrop;
