import React from 'react';
import styled from 'styled-components';
import { InquiryModal } from '../../constants/inquiry';

interface IProps {
  setIsPrivacy: (value: boolean) => void;
}

function PrivacyModal({ setIsPrivacy }: IProps) {
  const { title, contentTitle, content, img } = InquiryModal;
  return (
    <Container>
      <Modal>
        <Close src={img} onClick={() => setIsPrivacy(false)} />
        <Title>{title}</Title>
        <Content>
          <ContentTitle>{contentTitle}</ContentTitle>
          <ContentList>
            {content.map((item, i) => (
              <ContentItem key={i}>{item}</ContentItem>
            ))}
          </ContentList>
        </Content>
      </Modal>
      <Background />
    </Container>
  );
}

const Container = styled.div`
  width: auto;
  height: auto;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  z-index: 10;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 348px;
  padding: 20px;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: ${(props) => props.theme.tintColor.white};
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 394px;
    align-items: center;
    padding: 20px 11px 22px 11px;
  }
`;

const Close = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Title = styled.h5`
  margin-top: 44px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

const Content = styled.div`
  padding: 20px;
  border-radius: 12px;
  background: ${(props) => props.theme.grayScaleColor.grayScale5};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

const ContentTitle = styled.h5``;

const ContentList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  padding: 0 12px;
  @media ${(props) => props.theme.mobile} {
    padding: 0;
    gap: 10px;
    margin-top: 26px;
  }
`;

const ContentItem = styled.li`
  list-style: inside;
  white-space: pre-line;
  list-style-position: outside;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

export default PrivacyModal;
