import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubmitConents } from '../../constants/inquiry';
import { useForm } from 'react-hook-form';
import PrivacyModal from './PrivacyModal';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { applyFranchisee } from '../../api/application';
import { isMobile } from '../../util/isMobile';
import LoadingView from '../Common/LoadingView';

interface IProps {
  setIsComplete: (value: boolean) => void;
}

export interface IForm {
  company: string;
  name: string;
  phoneNumber: string;
  email: string;
  inquiry: string;
}

interface IError {
  value: number;
  reason: string;
  code: string;
  message: string;
  requestURI: string;
  requestMethod: string;
}

function Submit({ setIsComplete }: IProps) {
  const [anyChecked, setAnyChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);

  const { content, mobileContent, checkBox, checkBoxActive } = SubmitConents;

  // 임시 타이틀
  const title =
    useLocation().pathname === '/inquiry'
      ? '회사 정보와 문의 내용을 남겨주세요'
      : '준비중입니다.\n 회사 정보와 문의 내용을 남겨주세요.';

  // react-hook-form 사용하여 validate 설정
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      inquiry: '',
    },
  });
  const { company, name, phoneNumber, email, inquiry } = getValues();

  watch(['company', 'name', 'phoneNumber', 'email', 'inquiry']);
  useEffect(() => {
    if (company && name && phoneNumber && email && inquiry && isChecked) {
      setAnyChecked(true);
    } else {
      setAnyChecked(false);
    }
  }, [company, name, phoneNumber, email, inquiry, isChecked]);

  const { isLoading, mutate } = useMutation<void, IError, IForm>(
    (payload) => applyFranchisee(payload),
    {
      onSuccess: () => {
        setIsComplete(true);
      },
      onError: (error) => {
        alert(`서버와 통신이 원활하지 않습니다.\n다시 시도해주세요.`);
      },
    }
  );

  // 문의하기 버튼 클릭시 isComplete 상태를 true로 바꾸주어 새로운 컴포넌트 렌더링 하도록 구현
  const onValid = (data: IForm) => {
    if (!anyChecked) return;
    mutate(data);
  };

  if (isLoading) return <LoadingView />;
  return (
    <Container>
      <Title>{title}</Title>
      <Content onSubmit={handleSubmit(onValid)}>
        <ContentContainer>
          <ErrorContainer>
            <ContentInput
              {...register('company', { required: true })}
              placeholder='회사명'
            />
          </ErrorContainer>
          <ErrorContainer>
            <ContentInput
              {...register('name', { required: true })}
              placeholder='이름'
            />
          </ErrorContainer>
          <ErrorContainer>
            <ContentInput
              {...register('phoneNumber', {
                required: true,
                validate: {
                  phoneNumber: (value) => {
                    const valueNum = Number(value);
                    return !valueNum ? '숫자만 입력해주세요.' : true;
                  },
                },
              })}
              placeholder='연락처 (숫자만 입력)'
              maxLength={11}
            />
            <ErrorText>{errors?.phoneNumber?.message}</ErrorText>
          </ErrorContainer>
          <ErrorContainer>
            <ContentInput
              {...register('email', {
                required: true,
                pattern: {
                  value: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                  message: '이메일 형식에 맞지 않습니다. 다시 입력해주세요.',
                },
              })}
              placeholder='회사 이메일'
            />
            <ErrorText>{errors?.email?.message}</ErrorText>
          </ErrorContainer>
          <ErrorContainer>
            <ContentTextArea
              {...register('inquiry', { required: true })}
              placeholder={isMobile() ? mobileContent : content}
            />
          </ErrorContainer>
        </ContentContainer>
        <ContentPrivacy>
          <CheckBox
            src={isChecked ? checkBoxActive : checkBox}
            onClick={() => setIsChecked((prev) => !prev)}
          />
          <Privacy>
            <PrivactButton type='button' onClick={() => setIsPrivacy(true)}>
              개인정보 수집 및 이용 방침
            </PrivactButton>
            에 동의합니다.
          </Privacy>
        </ContentPrivacy>
        <ContentButton check={anyChecked} disabled={anyChecked ? false : true}>
          문의하기
        </ContentButton>
      </Content>
      {isPrivacy && <PrivacyModal setIsPrivacy={setIsPrivacy} />}
    </Container>
  );
}
const Container = styled.div`
  @media ${(props) => props.theme.mobile} {
    padding: 50px 0;
  }
`;
const Title = styled.h2`
  text-align: center;
  margin-bottom: 41px;
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  color: ${(props) => props.theme.tintColor.primary};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-top: 100px;
  }
`;

const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentInput = styled.input`
  width: 400px;
  height: 48px;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid ${(props) => props.theme.grayScaleColor.grayScale3};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  outline-color: ${(props) => props.theme.blueColor.scale3};
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 44px;
  }
`;

const ContentTextArea = styled.textarea`
  display: flex;
  width: 400px;
  height: 270px;
  border-radius: 4px;
  padding: 12px 16px;
  resize: none;
  white-space: pre-line;
  border: 1px solid ${(props) => props.theme.grayScaleColor.grayScale3};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  outline-color: ${(props) => props.theme.blueColor.scale3};
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    height: 141px;
  }
`;

const ContentPrivacy = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 16px;
  width: 400px;
  justify-content: flex-start;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    width: 335px;
    gap: 8px;
  }
`;

const CheckBox = styled.img`
  width: 20px;
  height: 20px;
`;

const Privacy = styled.div`
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
`;

const PrivactButton = styled.button`
  position: relative;
  padding: 0;
  background: ${(props) => props.theme.tintColor.white};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.tintColor.primary};
  ::after {
    display: block;
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.tintColor.primary};
    content: '';
  }
`;

const ContentButton = styled.button<{ check: boolean }>`
  width: 400px;
  height: 44px;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) =>
    props.check
      ? props.theme.tintColor.white
      : props.theme.grayScaleColor.grayScale2};
  background: ${(props) =>
    props.check
      ? props.theme.tintColor.gradient
      : props.theme.grayScaleColor.grayScale4};
  :disabled {
    cursor: default;
  }
  @media ${(props) => props.theme.mobile} {
    width: 335px;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const ErrorText = styled.span`
  font-size: ${(props) => props.theme.fontSize.light};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.light};
  color: ${(props) => props.theme.tintColor.erorr};
  @media ${(props) => props.theme.mobile} {
    text-align: left;
  }
`;

export default Submit;
