import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RendingIntroduceContent } from '../../constants/rendingExplain';
import { isMobile } from '../../util/isMobile';

function RendingIntroduce() {
  const imageRef = useRef<HTMLImageElement[]>([]);

  const observer = new IntersectionObserver((targets) => {
    targets.forEach((item: any) => {
      if (item.isIntersecting) {
        item.target.style.animationPlayState = 'running';
        item.target.style.transform = 'translateY(0px)';
      }
    });
  });

  useEffect(() => {
    observer.observe(imageRef.current[0]);
    observer.observe(imageRef.current[1]);
  }, []);

  return (
    <Wrapper>
      <Container>
        <ContentContainer>
          {RendingIntroduceContent.map((content, i) => (
            <Contents key={content.id}>
              <Title>{isMobile() ? content.mobileTitle : content.title}</Title>
              <Content>
                {isMobile() ? content.mobileContent : content.content}
              </Content>
              <Image
                src={content.img}
                ref={(el) => (imageRef.current[i] = el as HTMLImageElement)}
              />
            </Contents>
          ))}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.white};
  padding: 180px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 95px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 180px;
  @media ${(props) => props.theme.mobile} {
    gap: 63px;
  }
`;

const Title = styled.h3`
  z-index: 1;
  white-space: pre-wrap;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const Contents = styled.div`
  width: 100%;
`;

const Content = styled.div`
  margin: 24px 0 80px 0;
  white-space: pre-wrap;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
    margin-bottom: 15px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: contain;
  transform: translateY(50px);
  transition: transform 0.8s ease-in-out;
  animation: fadeIn 1s ease-in-out;
  animation-play-state: paused;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media ${(props) => props.theme.mobile} {
    height: 142px;
  }
`;

export default RendingIntroduce;
