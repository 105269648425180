import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MenuModalPage } from '../../constants/navigation';
import { close, logo } from '../../util/getImages';
import { INavigationProps } from './NavigationBar';

const MenuModal = ({ setIsMenuModal }: INavigationProps) => {
  const currentPage = useLocation();

  return (
    <Container>
      <Header>
        <Logo src={logo} />
        <Cancel src={close} onClick={() => setIsMenuModal(false)} />
      </Header>
      <PageList>
        {MenuModalPage.map((page) => (
          <PageItem key={page.id}>
            {currentPage.pathname === page.link ? (
              <TextContainer onClick={() => setIsMenuModal(false)}>
                <PageText>{page.title}</PageText>
              </TextContainer>
            ) : (
              <Link to={page.link}>
                <TextContainer>
                  <PageText>{page.title}</PageText>
                </TextContainer>
              </Link>
            )}
          </PageItem>
        ))}
      </PageList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.tintColor.white};
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid ${(props) => props.theme.grayScaleColor.grayScale4};
`;

const Logo = styled.img`
  width: 110px;
  height: 36px;
  object-fit: contain;
`;

const Cancel = styled.img`
  width: 24px;
  height: 24px;
`;

const PageList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const PageItem = styled.li`
  padding: 0 20px;
  :last-child {
    background: ${(props) => props.theme.blueColor.scale3};
    span {
      color: ${(props) => props.theme.tintColor.white};
    }
    :active {
      background: ${(props) => props.theme.blueColor.scale4};
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`;

const PageText = styled.span`
  color: ${(props) => props.theme.grayScaleColor.grayScale2};
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
`;

export default MenuModal;
