import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ErrorGraphContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import ErrorGraph from './ErrorGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function Refund({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='errorRefund'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        Refund
      </Title>
      <GraphContainer>
        <ErrorGraph content={ErrorGraphContent.refund} />
      </GraphContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 100px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    scroll-margin-top: 150px;
  }
`;

const GraphContainer = styled.div`
  @media ${(props) => props.theme.mobile} {
    overflow-x: auto;
    width: ${document.body.offsetWidth}px;
  }
`;
export default Refund;
