import styled from 'styled-components';
import {
  buttonGuide,
  buttonUse1,
  buttonUse2,
  buttonUse3,
  buttonUse4,
  logoUseGuide1,
  logoUseGuide2,
} from '../util/getImages';

const NavigationBarContent = [
  { id: 1, title: '개발 가이드', link: '/develope-guide', active: false },
  { id: 2, title: '디자인 가이드', link: '/design-guide', active: false },
];

const SideNavigationBarDesignContent = [
  {
    id: 1,
    title: '로고 가이드',
    link: '/design-guide',
    active: false,
  },
  {
    id: 2,
    title: '버튼 가이드',
    link: '/design-guide/button',
    active: false,
  },
];

const SideNavigationBarDevelopContent = [
  {
    id: 1,
    title: '개요',
    link: '/develope-guide',
    active: false,
  },
  {
    id: 2,
    title: '토큰 발급',
    link: '/develope-guide/token',
    active: false,
  },
  {
    id: 3,
    title: '에러코드',
    link: '/develope-guide/error',
    active: false,
  },
  {
    id: 4,
    title: '환급',
    link: '/develope-guide/refund',
    active: false,
    list: [
      {
        id: 1,
        title: '환급기능 및 한도조회',
        link: '/develope-guide/refund',
      },
      {
        id: 2,
        title: '환급 요청 및 완료',
        link: '/develope-guide/refund/apply',
      },
      {
        id: 3,
        title: '환급취소',
        link: '/develope-guide/refund/cancle',
      },
    ],
  },
];

const IndexDesignListContent = {
  logo: [
    {
      id: 1,
      content: '1. 로고',
      active: false,
      checkId: 'logo',
    },
    {
      id: 2,
      content: '2. 활용 가이드라인',
      active: false,
      checkId: 'useLogo',
    },
  ],
  button: [
    {
      id: 1,
      content: '1. 기본 버튼',
      active: false,
      checkId: 'basicButton',
    },
    {
      id: 2,
      content: '2. 비활성화 버튼',
      active: false,
      checkId: 'disableButton',
    },
    {
      id: 3,
      content: '3. 크기 변경',
      active: false,
      checkId: 'sizeChange',
    },
    {
      id: 4,
      content: '4. 적용 예시',
      active: false,
      checkId: 'apply',
    },
    {
      id: 5,
      content: '5. 활용 가이드라인',
      active: false,
      checkId: 'useButton',
    },
  ],
};

const LogoUseGuideContent = {
  title: '활용 가이드라인',
  dont: 'Don’t',
  contents: [
    {
      id: 1,
      img: logoUseGuide1,
      content: '로고의 비율을 왜곡하여 사용하지 않습니다.',
      dont: "Don't",
    },
    {
      id: 2,
      img: logoUseGuide2,
      content: '로고를 임의로 잘라서 사용하지 않습니다.',
      dont: "Don't",
    },
  ],
};

const ButtonBasicContent = {
  title: '기본 버튼',
  img: buttonGuide,
  contents: [
    {
      id: 1,
      title: '1. Container',
      content:
        '버튼의 배경에 적용되는 컬러는 라이트모드 / 다크모드에 따라 구분할 수 있습니다.\n Container의 radius는 8px로 적용합니다.',
    },
    {
      id: 2,
      title: '2. Label',
      content:
        '버튼의 내용을 나타내는 Label은 텍스트로 이루어져 있으며, 중앙정렬을 기본으로 합니다.',
    },
    {
      id: 3,
      title: '3. Logo',
      content: 'KTP Logo는 Label 좌측에 배열됩니다.',
    },
  ],
};

const ButtonUseGuideContent = {
  title: '활용 가이드라인',
  contents: [
    {
      id: 1,
      img: buttonUse1,
      content: '가이드에서 벗어난 logo을 사용하지 않습니다.',
      dont: "Don't",
    },
    {
      id: 2,
      img: buttonUse2,
      content: '가이드에서 벗어난 컬러를 사용하지 않습니다.',
      dont: "Don't",
    },
    {
      id: 3,
      img: buttonUse3,
      content: '임의의 아이콘을 사용하지 않습니다.',
      dont: "Don't",
    },
    {
      id: 4,
      img: buttonUse4,
      content: 'logo 없이 label만 사용하지 않습니다.',
      dont: "Don't",
    },
  ],
};

const LogoGraphContent = {
  logo: [
    { id: 1, name: '폰트', content: 'Inter V / Bold / Italic' },
    { id: 2, name: '텍스트 색상', content: '#005F83' },
  ],
  basic: [
    { id: 1, name: '폰트', content: 'Inter V / Bold / Italic' },
    {
      id: 2,
      name: '텍스트 색상',
      content: 'Light mode - #FFFFFF\n Dark mode - #00658C',
    },
    {
      id: 3,
      name: '배경 색상',
      content: 'Light mode - #00658C\n Dark mode - #FFFFFF',
    },
  ],
  disabled: [
    { id: 1, name: '폰트', content: 'Inter V / Bold / Italic' },
    { id: 2, name: '텍스트 색상', content: '#005F83' },
    { id: 3, name: '배경 색상', content: '#BDBDBD' },
  ],
};

export {
  NavigationBarContent,
  SideNavigationBarDesignContent,
  SideNavigationBarDevelopContent,
  IndexDesignListContent,
  LogoUseGuideContent,
  ButtonBasicContent,
  ButtonUseGuideContent,
  LogoGraphContent,
};
