import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { NavigationPage } from '../../constants/navigation';
import { logo, logoWhite, menu, menuWhite } from '../../util/getImages';
import { isMobile } from '../../util/isMobile';

export interface INavigationProps {
  setIsMenuModal: (isModal: boolean) => void;
  isInquiry?: boolean;
}

function NavigationBar({ isInquiry, setIsMenuModal }: INavigationProps) {
  const [scrollPosition, setScrollPosition] = useState(false);
  const [isTest, setIsTest] = useState(false);

  const currentUrl = useLocation();

  NavigationPage.forEach((page) => {
    if (page.link === currentUrl.pathname) {
      page.active = true;
    } else {
      page.active = false;
    }
  });
  let lastScroll = 0;

  // 스크롤시 GNB 배경색 변경 부분
  const navbarUpdate = () => {
    if (isInquiry) {
      return;
    }
    if (isMobile()) {
      let st = window.scrollY;
      if (st > lastScroll) {
        setScrollPosition(true);
      } else {
        if (window.scrollY < 20) {
          setIsTest(false);
          return;
        }
        setScrollPosition(false);
        setIsTest(true);
      }
      lastScroll = st;
    } else {
      let check = (window.scrollY || document.documentElement.scrollTop) > 20;
      setIsTest(check);
    }
  };

  useEffect(() => {
    if (isInquiry) {
      setIsTest(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', navbarUpdate);
  }, []);

  return (
    <>
      <Wapper scroll={scrollPosition} isTest={isTest}>
        <Container>
          <NavbarContainer>
            <LogoContainer>
              <Link to={'/'}>
                <LogoIcon src={!isTest ? logoWhite : logo} />
              </Link>
            </LogoContainer>
            <PageList>
              {NavigationPage.map((page) => (
                <PageItem key={page.id} isActive={page.active} scroll={isTest}>
                  <Link to={page.link}>{page.title}</Link>
                </PageItem>
              ))}
              <Link to={'/inquiry'}>
                <PageItemBtn scroll={isTest}>도입 문의</PageItemBtn>
              </Link>
            </PageList>
            <Menu
              src={isTest ? menu : menuWhite}
              onClick={() => setIsMenuModal(true)}
            />
          </NavbarContainer>
        </Container>
      </Wapper>
    </>
  );
}

const Wapper = styled.div<{ scroll: boolean; isTest: boolean }>`
  position: fixed;
  width: 100%;
  z-index: 10;
  filter: drop-shadow(0px 4px 6px rgba(117, 117, 117, 0.08));
  background: ${(props) =>
    props.isTest ? props.theme.tintColor.white : 'transparent'};
  transition: ${(props) =>
    props.scroll ? 'background 0.35s ease-in' : 'background 0.15s ease-in'};
  @media ${(props) => props.theme.mobile} {
    position: fixed;
    transform: ${(props) =>
      props.scroll ? 'translateY(-80px)' : 'translateY(0px)'};
    transition: all 500ms ease;
    padding: 24px 20px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  /* text-align: center; */
`;

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoIcon = styled.img`
  display: flex;
  width: 110px;
  height: 36px;
  object-fit: contain;
`;

const PageList = styled.ul`
  display: flex;
  gap: 48px;
  @media ${(props) => props.theme.mobile} {
    display: none;
  }
`;

const Menu = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  @media ${(props) => props.theme.desktop} {
    display: none;
  }
`;

const PageItem = styled.li<{ isActive: boolean; scroll: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWight.medium};
  font-size: ${(props) => props.theme.fontSize.regular};
  margin-top: 22px;
  a {
    color: ${(props) =>
      props.scroll && props.isActive
        ? props.theme.tintColor.primary
        : props.scroll
        ? props.theme.grayScaleColor.grayScale1
        : props.theme.tintColor.white};
    :hover {
      opacity: 0.8;
    }
  }
  ::after {
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    content: '';
    width: 100%;
    height: 1px;
    margin-bottom: 18px;
    background-color: ${(props) =>
      props.scroll
        ? props.theme.tintColor.primary
        : props.theme.tintColor.white};
  }
`;

const PageItemBtn = styled.button<{ scroll: boolean }>`
  width: 100px;
  height: 44px;
  margin: 12px 0;
  padding: 10px 20px;
  border-radius: 8px;
  color: ${(props) => props.theme.tintColor.primary};
  font-weight: ${(props) => props.theme.fontWight.medium};
  font-size: ${(props) => props.theme.fontSize.regular};
  background: ${(props) =>
    props.scroll ? props.theme.tintColor.primary : props.theme.tintColor.white};
  color: ${(props) =>
    props.scroll ? props.theme.tintColor.white : props.theme.tintColor.primary};
  :hover {
    opacity: 0.8;
  }
`;

export default NavigationBar;
