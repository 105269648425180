import { IForm } from './../../components/inquiry/Submit';
import api from '../index';

export const applyFranchisee = async (payload: IForm) => {
  const res = await api.post<void, IForm, null>(
    '/franchisee-applicants/register',
    payload
  );
  return res.data;
};
