import React from 'react';
import styled from 'styled-components';
import { RefundInquiryExplainContent } from '../../../../constants/developeGuideContent';

function Explain() {
  return (
    <Container>
      <Title>{RefundInquiryExplainContent.title}</Title>
      <Content>{RefundInquiryExplainContent.content}</Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  margin-top: 2px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 130px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    scroll-margin-top: 150px;
  }
`;

const Content = styled.div`
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.small};
  }
`;

export default Explain;
