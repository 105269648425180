import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function RendingBottomBanner() {
  return (
    <Wrapper>
      <Container>
        <Title>기업 전문 텍스프리 KTP</Title>
        <ImportTitle>KTP와 함께 매출을 올려보세요!</ImportTitle>
        <Link to={'/inquiry'}>
          <Button>도입문의</Button>
        </Link>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tintColor.primary};
  padding: 80px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 60px 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  text-align: center;
  color: ${(props) => props.theme.tintColor.white};
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.regular};
    line-height: ${(props) => props.theme.mobileLineHeight.regular};
  }
`;

const ImportTitle = styled.h1`
  margin: 12px 0 44px 0;
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    margin-bottom: 32px;
  }
`;

const Button = styled.button`
  padding: 12px 44px;
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
  color: ${(props) => props.theme.tintColor.primary};
  filter: drop-shadow(0px 8px 16px rgba(0, 101, 140, 0.3));
  :hover {
    opacity: 80%;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.regular};
    line-height: ${(props) => props.theme.mobileLineHeight.regular};
    padding: 8px 44px;
  }
`;

export default RendingBottomBanner;
