import React, { useRef } from 'react';
import styled from 'styled-components';

interface IndexList {
  id: number;
  content: string;
  active: boolean;
  checkId: string;
}

interface IProps {
  indexList: IndexList[];
  currActive?: string;
}

function SideNavigationIndex({ indexList, currActive }: IProps) {
  const indexRef = useRef();

  indexList.forEach((item) => {
    if (currActive === item.checkId) {
      item.active = true;
    } else {
      item.active = false;
    }
  });

  const moveToContent = (checkId: string) => {
    const position = document.getElementById(checkId);
    if (position && (checkId === 'logo' || checkId === 'basicButton')) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }
    position?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <Wrapper>
      <Container>
        <IndexTitle>목차</IndexTitle>
        <IndexList>
          {indexList.map((item) => (
            <IndexItem
              key={item.id}
              ref={indexRef.current}
              active={item.active}
              onClick={() => moveToContent(item.checkId)}
            >
              {item.content}
            </IndexItem>
          ))}
        </IndexList>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 73px;
  padding-top: 100px;
  background-color: ${(props) => props.theme.tintColor.white};
`;

const IndexTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
`;

const IndexList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const IndexItem = styled.li<{ active?: boolean }>`
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) =>
    props.active
      ? props.theme.tintColor.primary
      : props.theme.grayScaleColor.grayScale2};
`;

export default SideNavigationIndex;
