import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DesignButton from '../components/DesignGuide/DesignButton';
import DesignLogo from '../components/DesignGuide/DesignLogo';
import Layout from '../components/layout/Layout';
import ScrollTop from '../hooks/ScrollTop';
import DesignGuidePage from '../pages/DesignGuidePage';
import DevelopGuidePage from '../pages/DevelopGuidePage';
import InquiryPage from '../pages/InquiryPage';
import RendingPage from '../pages/RendingPage';
import ServiceIntroducePage from '../pages/ServiceIntroducePage';
import TaxFreePage from '../pages/TaxFreePage';
import DevelopSummary from '../components/DevelopGudie/DevelopSummary';
import DevelopToken from '../components/DevelopGudie/DevelopToken';
import DevelopError from '../components/DevelopGudie/DevelopError';
import RefundInquiry from '../components/DevelopGudie/DevelopRefund/RefundInquiry';
import RefundApply from '../components/DevelopGudie/DevelopRefund/RefundApply';
import RefundCancle from '../components/DevelopGudie/DevelopRefund/RefundCancle';

function RouterContainer() {
  return (
    <Router>
      <Layout>
        <ScrollTop />
        <Routes>
          <Route path='/' element={<RendingPage />} />
          <Route path='/taxfree' element={<TaxFreePage />} />
          <Route path='/service-introduce' element={<ServiceIntroducePage />} />
          <Route path='/develope-guide' element={<DevelopGuidePage />}>
            <Route index element={<DevelopSummary />} />
            <Route path='token' element={<DevelopToken />} />
            <Route path='error' element={<DevelopError />} />
            <Route path='refund' element={<RefundInquiry />} />
            <Route path='refund/apply' element={<RefundApply />} />
            <Route path='refund/cancle' element={<RefundCancle />} />
          </Route>
          <Route path='/design-guide' element={<DesignGuidePage />}>
            <Route index element={<DesignLogo />} />
            <Route path='button' element={<DesignButton />} />
          </Route>
          <Route path='/inquiry' element={<InquiryPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default RouterContainer;
