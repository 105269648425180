import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import RendingHomeContent from './RendingHomeContent';
import BannerPC from '../../assets/rending/banner_pc.png';
import BannerMobile from '../../assets/rending/banner_mobile.png';

function RendingHome() {
  return (
    <Wrapper imgUrl={isMobile ? BannerMobile : BannerPC}>
      <HomeContainer>
        <RendingHomeContent />
      </HomeContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ imgUrl: string }>`
  background: url(${(props) => props.imgUrl});
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 132px 0 92px 0;
  @media ${(props) => props.theme.desktop} {
    align-items: center;
  }
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    padding: 93px 0 25px 0;
  }
`;

export default RendingHome;
