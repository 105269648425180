import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import LogoIcon from '../../assets/common/logo.png';
import AppStoreIcon from '../../assets/footer/app_store.png';
import GooglePlayIcon from '../../assets/footer/google_play.png';
import BlogIcon from '../../assets/footer/naver_blog.png';
import KakaoIcon from '../../assets/footer/kakao_channel.png';
import ChevronDownIcon from '../../assets/common/chevron_down.png';
import ChevronUpIcon from '../../assets/common/chevron_up.png';
import useDeviceCheck from '../../hooks/useCheckDevice';

function Footer() {
  const { pathname } = useLocation();
  const [isOpenToggle, setIsOpenToggle] = useState(false);
  const onToggle = () => {
    setIsOpenToggle((prev) => !prev);
  };
  const { isTablet } = useDeviceCheck();
  return (
    <Container>
      <Inner>
        <FlexWrap>
          <LazyWrapper>
            <Logo src={LogoIcon} alt='주식회사 케이티피.' />
          </LazyWrapper>

          {isTablet && (
            <>
              <ToggleBtn onClick={onToggle}>
                사업자 정보
                <ArrowIcon
                  src={isOpenToggle ? ChevronUpIcon : ChevronDownIcon}
                  width='24px'
                  height='24px'
                  alt='사업자 정보 더보기.'
                />
              </ToggleBtn>
              <Content>
                이메일: ktp@ktaxpay.com {isTablet ? <br /> : 'ㅣ '}고객센터:
                02-6275-8011
              </Content>
            </>
          )}
          <TogleBtnContainer>
            {!isTablet && (
              <ToggleBtn style={{ marginTop: 0 }} onClick={onToggle}>
                사업자 정보
                <ArrowIcon
                  src={isOpenToggle ? ChevronUpIcon : ChevronDownIcon}
                  width='24px'
                  height='24px'
                  alt='사업자 정보 더보기.'
                />
              </ToggleBtn>
            )}
            <ToggleArea
              isOpenToggle={isOpenToggle}
              dir={isTablet ? 'column' : 'row'}
            >
              <Content>
                상호명: 주식회사 케이티피 ㅣ 대표 : 주병천{' '}
                {isTablet ? <br /> : 'ㅣ '}
                사업자등록번호: 412-88-02679
                <br />
                주소 : 경기도 안양시 동안구 시민대로327번길 11-41, 3층 3265호
                <br />
              </Content>{' '}
            </ToggleArea>
            <Content>
              {!isTablet &&
                '이메일: ktp@ktaxpay.com | 고객센터: 02-6275-8011\n\nⓒ 2022 KTP Corp.'}
            </Content>
          </TogleBtnContainer>

          <SnsContainer>
            <CircleBtn
              href='https://blog.naver.com/successmode'
              target='_blank'
            >
              <SnsIcon src={BlogIcon} alt='네이버 블로그.' />
            </CircleBtn>
            <CircleBtn href='http://pf.kakao.com/_fFgsb' target='_blank'>
              <SnsIcon src={KakaoIcon} alt='카카오 채널.' />
            </CircleBtn>
          </SnsContainer>
          <StoreBtnWrapper isOpenToggle={isOpenToggle}>
            <StoreBtn
              href='https://play.google.com/store/apps/details?id=com.successmode.ktp'
              target='_blank'
            >
              <StoreBtnImgWrapper>
                <Icon src={GooglePlayIcon} alt='구글플레이스토어.' />
                Google Play
              </StoreBtnImgWrapper>
            </StoreBtn>

            <StoreBtn
              href='https://apps.apple.com/kr/app/ktp/id1566544989'
              target='_blank'
            >
              <StoreBtnImgWrapper>
                <Icon src={AppStoreIcon} alt='앱스토어.' />
                App Store
              </StoreBtnImgWrapper>
            </StoreBtn>
          </StoreBtnWrapper>
        </FlexWrap>
        {isTablet && <CopyrightText>ⓒ 2022 KTP Corp.</CopyrightText>}
      </Inner>
    </Container>
  );
}
const Container = styled.footer`
  background-color: #fff;
  padding: 5rem 0;
`;
const FlexWrap = styled.div`
  display: flex;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
  }
`;
const SnsContainer = styled.div`
  display: flex;
`;
const Inner = styled.div`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;
const Logo = styled.img`
  width: 100px;
  height: 30px;
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const Content = styled.p`
  color: #3a3b3e;
  opacity: 0.75;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 90px;
  transition: all 300ms ease-in-out;
  height: auto;
  white-space: pre-wrap;
  @media ${(props) => props.theme.mobile} {
    margin-right: 0px;
  }
`;
const LazyWrapper = styled.div`
  width: 180px;
  height: 52px;
  margin-right: 40px;
  @media ${(props) => props.theme.mobile} {
    width: 100px;
    height: 29px;
  }
`;

const ToggleBtn = styled.button`
  font-size: 14px;
  line-height: 20px;
  color: #3a3b3e;
  background-color: transparent;
  opacity: 0.75;
  padding: 0;
  font-weight: 400;
  margin-top: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
const ToggleArea = styled(FlexWrap)<{ isOpenToggle: boolean }>`
  transition: all 300ms ease-in-out;
  display: block;
  ${(props) =>
    !props.isOpenToggle &&
    css`
      height: 0;
      display: none;
      margin: 0;
    `};
`;
const ArrowIcon = styled.img`
  margin-left: 4px;
`;
const SnsIcon = styled.img`
  width: 48px;
  height: 48px;
`;
const CircleBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  margin-left: 12px;
  transition: all 300ms ease-in-out;
  @media ${(props) => props.theme.mobile} {
    margin: 12px 12px 0 0;
  }
`;
const StoreBtnWrapper = styled.div<{ isOpenToggle: boolean }>`
  display: flex;
`;
const StoreBtn = styled.a`
  margin-left: 14px;
  @media ${(props) => props.theme.mobile} {
    margin: 12px 12px 12px 0;
  }
`;
const StoreBtnImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #1856cd;
  font-weight: 600;
  border: 2px solid #1856cd;
  border-radius: 4px;
  transition: all ease-in-out 300ms;
  width: 160px;
  height: 48px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
const CopyrightText = styled.p`
  color: #3a3b3e;
  opacity: 0.75;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding-top: 8px;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
    margin: 12px 12px 12px 0;
    border-top: 1px solid rgba(58, 59, 62, 0.3);
  }
`;
const TogleBtnContainer = styled(FlexWrap)`
  flex-direction: column;
  margin-top: 0;
  width: 500px;
`;
export default Footer;
