import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import BannerPC from '../../assets/service/banner_pc.png';
import BannerMobile from '../../assets/service/banner_mobile.png';

function Home() {
  return (
    <Wrapper imgUrl={isMobile ? BannerMobile : BannerPC}>
      <TaxFreeContainer>
        <Title>
          기업 맞춤형
          <br />
          부가세 환급 서비스, KTP
        </Title>
        <Link to={'/inquiry'}>
          <Button>도입 문의</Button>
        </Link>
      </TaxFreeContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ imgUrl: string }>`
  background: url(${(props) => props.imgUrl});
  width: 100%;
  padding: 208px 0 140px 0;
  margin: 0 auto;
  text-align: center;
  @media ${(props) => props.theme.mobile} {
    padding: 98px 0 78px 0;
  }
`;

const TaxFreeContainer = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  color: ${(props) => props.theme.tintColor.white};
  margin-bottom: 48px;
  @media ${(props) => props.theme.mobile} {
    font-size: 30px;
    line-height: 38px;
    margin: 30px 0 20px;
  }
`;

const Button = styled.button`
  width: 148px;
  height: 44px;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 16px rgba(0, 101, 140, 0.3));
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.tintColor.primary};
  background: ${(props) => props.theme.tintColor.white};
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.8;
  }
`;

export default Home;
