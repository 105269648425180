import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { RendingCooper } from '../../constants/rendingExplain';
import { isMobile } from '../../util/isMobile';

function Cooperation() {
  const { title, imgs, mobileTitle, mobileImgs } = RendingCooper;
  const imageRef = useRef<HTMLDivElement[]>([]);
  const mobileFirst = useRef<HTMLDivElement[]>([]);
  const mobileSecond = useRef<HTMLDivElement[]>([]);

  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    if (isMobile()) {
      mobileFirst.current[0].style.animationPlayState = 'running';
      mobileSecond.current[0].style.animationPlayState = 'running';
    } else {
      imageRef.current[0].style.animationPlayState = 'running';
    }
    observer.disconnect();
  });

  useEffect(() => {
    if (isMobile()) {
      observer.observe(mobileFirst.current[0]);
      observer.observe(mobileSecond.current[0]);
    } else {
      observer.observe(imageRef.current[0]);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <CooperTitle>{isMobile() ? mobileTitle : title}</CooperTitle>

        {isMobile() ? (
          <CooperWrapper>
            <SlideContainer
              ref={(el) => (mobileFirst.current[0] = el as HTMLDivElement)}
            >
              {mobileImgs[0].map((img) => (
                <Image key={img.id} src={img.orderImg} />
              ))}
            </SlideContainer>
            <SlideContainer
              second
              ref={(el) => (mobileSecond.current[0] = el as HTMLDivElement)}
            >
              {mobileImgs[1].map((img) => (
                <Image key={img.id} src={img.orderImg} />
              ))}
            </SlideContainer>
          </CooperWrapper>
        ) : (
          <SlideContainer
            ref={(el) => (imageRef.current[0] = el as HTMLDivElement)}
          >
            {imgs.map((img) => (
              <Image key={img.id} src={img.orderImg} />
            ))}
          </SlideContainer>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding-top: 120px;
  background: ${(props) => props.theme.blueColor.scale1};
  @media ${(props) => props.theme.mobile} {
    padding-top: 48px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  text-align: center;
  overflow-x: hidden;
  ::before,
  ::after {
    content: '';
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 5%;
    height: 60px;
    z-index: 2;
    position: absolute;
    bottom: 120px;
  }
  ::before {
    left: 0;
  }
  ::after {
    right: 0;
    transform: rotateZ(180deg);
  }
  @media ${(props) => props.theme.mobile} {
    ::before,
    ::after {
      width: 10%;
      height: 140px;
      bottom: 50px;
    }
  }
`;

const CooperTitle = styled.h2`
  margin-bottom: 68px;
  text-align: center;
  white-space: pre-wrap;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  font-size: ${(props) => props.theme.fontSize.semibold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.semiBold};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.medium};
    line-height: ${(props) => props.theme.mobileLineHeight.medium};
    margin-bottom: 24px;
  }
`;

const CooperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;
`;

const SlideContainer = styled.div<{ second?: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: calc(220px * 20);
  padding-bottom: 120px;
  animation: ${(props) => (props.second ? 'reversSlide' : 'slide')}
    ${isMobile() ? '62s' : '40s'} linear infinite;
  animation-play-state: paused;
  gap: 20px;
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 10));
    }
  }
  @keyframes reversSlide {
    0% {
      transform: translateX(calc(-200px * 10));
    }
    100% {
      transform: translateX(0);
    }
  }
  @media ${(props) => props.theme.mobile} {
    padding-bottom: 0px;
  }
`;

const Image = styled.img`
  padding: 15px 30px;
  width: 180px;
  height: 60px;
  border-radius: 8px;
  object-fit: contain;
  background: ${(props) => props.theme.tintColor.white};
  box-shadow: 0px 4px 16px rgba(218, 222, 228, 0.8);
`;

export default Cooperation;
