import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IndexDevelopListContent } from '../../../constants/developeGuideContent';
import { isMobile } from '../../../util/isMobile';
import MobileSideMenu from '../../layout/MobileSideMenu';
import SideNavigationIndex from '../../layout/SideNavigationIndex';
import ApiData from './ApiData';
import Calling from './Calling';
import KeyAccept from './KeyAccept';
import ResultData from './ResultData';
import ServiceFlow from './ServiceFlow';
import ServiceProcess from './ServiceProcess';

function DevelopSummary() {
  const [currActive, setCurrActive] = useState('summary');
  const indexList = IndexDevelopListContent.summary;

  const curr: any = useLocation();

  useEffect(() => {
    if (curr?.state) {
      const location = curr?.state;
      const element = document.querySelector(`#${location.location}`);
      element?.scrollIntoView({ block: 'start' });
    }
  }, [curr]);

  return (
    <Wrapper>
      <Container>
        <ServiceProcess setCurrActive={setCurrActive} />
        <KeyAccept setCurrActive={setCurrActive} />
        <ServiceFlow setCurrActive={setCurrActive} />
        <Calling setCurrActive={setCurrActive} />
        <ApiData setCurrActive={setCurrActive} />
        <ResultData setCurrActive={setCurrActive} />
      </Container>
      {isMobile() ? (
        <MobileSideMenu currActive={currActive} />
      ) : (
        <SideNavigationIndex indexList={indexList} currActive={currActive} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  margin-left: 2%;
  flex: 1;
  @media ${(props) => props.theme.mobile} {
    margin: 0 16px;
    gap: 0px;
    padding-top: 115px;
    /* overflow: hidden; */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 80px 0px 56px 0px;
  color: ${(props) => props.theme.grayScaleColor.grayScale1};
  background: ${(props) => props.theme.tintColor.white};
  max-width: 860px;
  @media ${(props) => props.theme.mobile} {
    padding-top: 0;
    gap: 60px;
  }
`;

export default DevelopSummary;
