import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ResultDataContent } from '../../../constants/developeGuideContent';
import { getIntersectionObserver } from '../../../util/observer';
import DevelopCode from '../DevelopCode';
import DevelopGraph from '../DevelopGraph';

interface IProps {
  setCurrActive: (value: string) => void;
}

function ResultData({ setCurrActive }: IProps) {
  const targetRef = useRef<HTMLHeadingElement>();

  const observer = getIntersectionObserver(setCurrActive);
  useEffect(() => {
    observer.observe(targetRef.current!);
  }, []);

  return (
    <Container>
      <Title
        id='resultData'
        ref={(el) => (targetRef.current = el as HTMLHeadingElement)}
      >
        Result Data
      </Title>
      <ContentList>
        {ResultDataContent.map((item) => (
          <ContentItem key={item.id}>
            {item.subTitle && <SubTitle>{item.subTitle}</SubTitle>}
            <Content>{item.content}</Content>
            {item.graph && (
              <DevelopGraph
                content={item.graph.content}
                head={item.graph.head}
              />
            )}
            {item.code && <DevelopCode content={item.code} />}
          </ContentItem>
        ))}
      </ContentList>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media ${(props) => props.theme.mobile} {
    gap: 28px;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  scroll-margin-top: 120px;
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
    scroll-margin-top: 150px;
  }
`;

const ContentList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ContentItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 25px;
  white-space: pre-line;
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  @media ${(props) => props.theme.mobile} {
    font-size: ${(props) => props.theme.mobileFontSize.large};
    line-height: ${(props) => props.theme.mobileLineHeight.large};
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.medium};
  line-height: ${(props) => props.theme.lineHeight.normal};
`;

const Content = styled.div`
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: 32px;
  @media ${(props) => props.theme.mobile} {
    padding-right: 16px;
    font-size: ${(props) => props.theme.mobileFontSize.small};
    line-height: ${(props) => props.theme.mobileLineHeight.regular};
  }
`;

export default ResultData;
