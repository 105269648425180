import React from 'react';
import styled from 'styled-components';
import RendingHomeButton from './RendingHomeButton';

function RendingHomeContent() {
  return (
    <HomeContainer>
      <Title>
        기업 맞춤형
        <br />
        부가세 환급 서비스, KTP
      </Title>
      <HomeContent>
        기업 맞춤형 텍스프리 서비스를 통해
        <br />
        매출 증대와 고객 만족도 향상을 경험해보세요
      </HomeContent>
      <RendingHomeButton />
    </HomeContainer>
  );
}

const HomeContainer = styled.div``;

const Title = styled.h1`
  white-space: pre-wrap;
  color: ${(props) => props.theme.tintColor.white};
  font-size: ${(props) => props.theme.fontSize.bold};
  font-weight: ${(props) => props.theme.fontWight.bold};
  line-height: ${(props) => props.theme.lineHeight.bold};
  @media ${(props) => props.theme.mobile} {
    font-weight: ${(props) => props.theme.fontWight.medium};
    margin-top: 175px;
    font-size: 30px;
    line-height: 38px;
  }
`;

const HomeContent = styled.h3`
  margin: 17px 0 48px 0;
  white-space: pre-wrap;
  color: ${(props) => props.theme.tintColor.white};
  font-size: ${(props) => props.theme.fontSize.noraml};
  font-weight: ${(props) => props.theme.fontWight.regular};
  line-height: ${(props) => props.theme.lineHeight.normal};
  @media ${(props) => props.theme.mobile} {
    margin: 17px 0 175px 0;
    font-size: ${(props) => props.theme.mobileFontSize.xsmall};
    line-height: ${(props) => props.theme.mobileLineHeight.xsmall};
  }
`;

export default RendingHomeContent;
